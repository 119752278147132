import React from 'react'

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
import { getSelectedCompany } from 'selectors'

import { Loading, Nav } from './Nav'

const companyNavItems = [{
    name: 'Applications',
    icon: 'adn',
    route: 'applications'
}, {
    name: 'Customers',
    icon: 'address card',
    route: 'licenses'
}, {
    name: 'Team',
    icon: 'users',
    route: 'team'
}, {
    name: 'Billing',
    icon: 'file alternate',
    route: 'billing'
}, {
    name: 'Settings',
    icon: 'cog',
    route: 'settings',

    children: [{
        name: 'Company Details',
        icon: '',
        route: 'company'
    }, {
        name: 'FastSpring Integration',
        icon: '',
        route: 'fastspring'
    }]
}]



class NavCompany extends React.Component {

    selectItem(item, routePrefix) {
        const { performAppNav, match: { params: { companyId } } } = this.props

        if (item.subRoute) {
            const route = `${item.route}/${item.subRoute}`
            performAppNav({companyId, route })
        }
        else {
            const route = routePrefix ? `${routePrefix}/${item.route}` : item.route
            performAppNav({companyId, route })
        }
    }

    showCompanies() {
        const { performAppNav } = this.props
        performAppNav()
    }

    render() {
        const { performAppNav, company, match: { params: { mode, subMode } } } = this.props

        if (!company) return (<Loading />)

        return (
            <Nav items={companyNavItems} company={company} mode={mode} subMode={subMode} onBack={() => this.showCompanies()} selectItem={(item, routePrefix) => this.selectItem(item, routePrefix)} />
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        company: getSelectedCompany(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavCompany)

import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';
import { Form, Input } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { companyUpdate } from 'actions/company'
import { performAppNav } from 'actions/nav'
import { getSelectedCompany } from 'selectors'

import ContentContainer from 'ContentContainer'
import { StyledButton } from 'StyledButton'

import styled from 'styled-components'

import { FileDropzone } from 'FileDropzone'

import { ImageSrc } from 'DevAntMethods'

const StyledContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 2em;
`

const StyledForm = styled(Form)`
    width: 100%;
    max-width: 600px !important;
`

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    // padding: 2em;
`

const StyledButtons = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px !important;
`
const ButtonSpacer = styled.div`
    flex: 1;
`

const logoDescription = 'Upload your logo here. The image should be in .png or .jpg format (.png with transparent background is the best option).'

class CompanySettingsDetails extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            company: null
        }
    }

    componentDidMount() {
        const { company } = this.props
        this.setState({
            company
        })
    }

    updateCompany(event, name) {
        const { company } = this.state
        this.setState({
            company: {
                ...company,
                [name] : event.target.value
            }
        })
    }

    onCancel() {
        const { performAppNav, match: { params: { companyId } } } = this.props
        performAppNav({ companyId, route:'settings' })
    }

    onSave() {
        const { companyUpdate } = this.props
        const { company, file } = this.state

        companyUpdate(company, file)
    }

    onFile(file) {
        this.setState({
            file
        })

        // POST to a test endpoint for demo purposes
        // const req = request.post('https://httpbin.org/post');
        //
        // files.forEach(file => {
        //     req.attach(file.name, file);
        // });
        //
        // req.end();
    }

    render() {
        const { company } = this.state

        if (!company) return null

        const { name="", website="", icon={} } = company
        const { imageId, imageExt } = icon

        const imageSrc = ImageSrc(imageId, imageExt, 128)

        const buttons = (
            <StyledButtonContainer>
                <StyledButtons>
                    <StyledButton outline basic onClick={ () => this.onCancel() }>Cancel</StyledButton>
                    <ButtonSpacer />
                    <StyledButton outline green onClick={ () => this.onSave() }>Save Changes</StyledButton>
                </StyledButtons>
            </StyledButtonContainer>
        )

        return (
            <ContentContainer buttons={buttons} hugContent>

                <StyledContentContainer>
                    <StyledForm>
                        <Form.Field>
                            <label>Company Name</label>
                            <Input placeholder='Company Name' value={name} onChange={ (e) => this.updateCompany(e, 'name') } />
                        </Form.Field>

                        <Form.Field>
                            <label>Website Address</label>
                            <input placeholder='Website' value={website} onChange={ (e) => this.updateCompany(e, 'website') } />
                        </Form.Field>

                        <Form.Field>
                            <label>Company Logo</label>
                            <FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={imageSrc} description={logoDescription} />
                        </Form.Field>
                    </StyledForm>
                </StyledContentContainer>

            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        company: getSelectedCompany(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params)),
    companyUpdate: (...params) => dispatch(companyUpdate(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySettingsDetails)

import { createSelector } from 'reselect'


// ROUTE PARAMS
export const getSelectedCompanyId = (state, { match: { params : { companyId } } }) => companyId
export const getSelectedAppId = (state, { match: { params : { appId } } }) => appId
export const getSelectedMode = (state, { match: { params : { mode } } }) => mode


// USER
export const getUser = state => state.user.user


// COMPANY
export const getCompanies = state => Object.values(state.companies.companiesById)
export const getSelectedCompany = createSelector(
    [getSelectedCompanyId, getCompanies],
    (companyId, companies) => {
        return companies.filter(company => company.companyId === companyId)[0]
    }
)


// APP
export const getAllApps = state => Object.values(state.apps.appsById)
export const getAppsLoading = state => state.apps.isLoading
export const getApps = createSelector(
    [getSelectedCompanyId, getAllApps],
    (companyId, apps) => {
        return apps.filter(app => app.companyId === companyId)
    }
)
export const getSelectedApp = createSelector(
    [getSelectedAppId, getAllApps],
    (appId, apps) => {
        return apps.filter(app => app.appId === appId)[0]
    }
)

// RELEASES
// export const getAllReleases = state => Object.values(state.apps.appsById)
export const getReleases = createSelector(
    [getSelectedCompanyId, getAllApps],
    (companyId, apps) => {
        return apps.filter(app => app.companyId === companyId)
    }
)

import React from 'react'
// import { Button, Header, Input} from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search } from 'actions/issues'
import { getSelectedIssueId, getIssues, getIssuesLoading } from 'selectors/issues'

import ContentContainer from 'ContentContainer'

import IssuesList from './IssuesList'
import IssueViewer from './IssueViewer'

import styled from 'styled-components'



const LicensesContentLeft = styled.div`
    flex: 20%;
    overflow: scroll;
    border-right: 1px solid #d8d8d8 !important;
`
const LicensesContentRight = styled.div`
    overflow: scroll;
    background: rgb(251, 251, 251);
`
const ContentFlex = styled.div`
    display: flex;
    height: 100%;
`




class ApplicationIssues extends React.Component {

    async componentDidMount() {
        const { issues, search, match: { params: { appId, companyId } } } = this.props
        if (issues.length) return
        search(companyId, appId)
    }

    render() {
        const { selectedIssueId, isLoading, issues } = this.props

            // const header = (
            //     <React.Fragment>
            //         <Button size='mini' basic floated='right' icon='add' content="New Customer" onClick={() => { this.newCustomer() }}/>
            //         <Input size='mini' icon='search' placeholder='Search...' value={search} onChange={(event) => this.handleSearch(event)} />
            //     </React.Fragment>
            // )

            //header={header}

//<LicenseEditor {...this.props} issueId={selectedIssueId} />
        return (
            <ContentContainer padding loading={isLoading} emptyContent={Object.keys(issues).length === 0}>
                <ContentFlex>
                    <LicensesContentLeft>
                        <IssuesList {...this.props} />
                    </LicensesContentLeft>

                    <LicensesContentRight>
                        <IssueViewer issueId={selectedIssueId} />
                    </LicensesContentRight>
                </ContentFlex>
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        selectedIssueId: getSelectedIssueId(state),
        issues: getIssues(state, props),
        isLoading: getIssuesLoading(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, searchValue) => dispatch(search(companyId, appId, searchValue))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationIssues)

export default {
    // https://serverless-stack.com/chapters/configure-aws-amplify.html
    // s3: {
    //     REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    //     BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    // },
    // apiGateway: {
    //     REGION: "us-east-1",
    //     URL: "https://99ne97d1y9.execute-api.us-east-1.amazonaws.com/dev"
    // },
    // cognito: {
    //     REGION: "us-east-1",
    //     USER_POOL_ID: "us-east-1_NOQMtT6Ld",
    //     APP_CLIENT_ID: "768sthstmfvjvbihbc5n8c1d41",
    //     IDENTITY_POOL_ID: "us-east-1:1eeec98c-422d-4ef0-be01-8725d2c56833"
    // },

  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    // userPoolId: "us-east-1_NOQMtT6Ld",
    userPoolId: "us-east-1_jW4kPQqm7",
    identityPoolId: "us-east-1:c5064823-73a3-4fdf-aa03-17f2095c4905",
    // userPoolWebClientId: "768sthstmfvjvbihbc5n8c1d41"
    userPoolWebClientId: "15usl4jfqj4urjcqvc5959f7pk"
  },
  // Auth71Squared: {
  //   mandatorySignIn: true,
  //   region: "us-east-1",
  //   // userPoolId: "us-east-1_NOQMtT6Ld",
  //   userPoolId: "us-east-1_nmyVK01D2",
  //   identityPoolId: "us-east-1:9e7bce1c-2c2b-4d55-9b38-7ebcbb8ab79c",
  //   // userPoolWebClientId: "768sthstmfvjvbihbc5n8c1d41"
  //   userPoolWebClientId: "18m59fscuseg3kvo650ad47f1k"
  // },
  Storage: {
    region: "us-east-1",
    bucket: "prod-service-devant-storage",
    // bucket: "dev-service-activations-storage", 71Squared
    // identityPoolId: "us-east-1:9e7bce1c-2c2b-4d55-9b38-7ebcbb8ab79c"
  },
  API: {
    endpoints: [
      {
        name: "activations",
        //endpoint: "https://99ne97d1y9.execute-api.us-east-1.amazonaws.com/dev", 71squared
        // endpoint: "https://3o4l7nre4a.execute-api.us-east-1.amazonaws.com/prod",
        endpoint: "https://api.devant.io/v1",
        region: "us-east-1",
      },
    ]
  }
}

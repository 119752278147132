import { Actions } from 'Constants'

import { defaultListState, processList } from './InfiniteListReducer'

const defaultState = {
    list: defaultListState,
    selectedId: undefined,
    showEditor: false,

    isLoading: true,
    licenseTypesById: {}
}

const licenseTypeId = (licenseType) => { return licenseType.licenseTypeId }

const LicenseTypes = (state = defaultState, action) => {
    switch (action.type) {

        case Actions.LICENSE_TYPE_DATA:
            const { isLoading = false, licenseTypes = [] } = action.payload
            const licenseTypesById = isLoading ? {} : licenseTypes.reduce((result, licenseType) => {
                return { ...result, [licenseType.licenseTypeId]:licenseType };
            }, state.licenseTypesById)

            return {
                ...state,
                isLoading,
                licenseTypesById
            }

        case Actions.UI_LICENSE_TYPES_LIST:
            return {
                ...state,
                list: processList(state.list, action, licenseTypeId)
            }

        // case Actions.UI_CHANGE_COMPANY:
        //     return defaultState
        //
        // case Actions.UI_CHANGE_APP:
        //     return defaultState

        case Actions.UI_LICENSE_TYPES_SELECT:
            return {
                ...state,
                selectedId: action.licenseType.licenseTypeId,
                showEditor: false
            }

        case Actions.UI_LICENSES_TYPES_TOGGLE_EDITOR: {
            return {
                ...state,
                showEditor: !state.showEditor,
                editingLicenseType: action.licenseType,
                createNewRecord: action.createNewRecord
            }
        }

        default:
        return state
    }
}

export default LicenseTypes

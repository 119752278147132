import React from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components'

import { getSelectedApp } from 'selectors'
import { appStats } from 'selectors/appStats'
import { search } from 'actions/appStats'

import DevAntChart from './Dashboard/DevAntChart'

import { options as AppOverviewOptions } from './Dashboard/AppOverviewConfig'
import { options as AppLaunchesOptions } from './Dashboard/AppLaunchesConfig'

const axis = ({id, label, borderColor, backgroundColor, borderWidth=2, fill=true, tension=0}) => ({
    yAxisID: id,
    label,
    fill,
    lineTension: tension,
    backgroundColor,

    borderColor,
    borderWidth,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',

    pointRadius: 2,
    pointHitRadius: 10,
    pointBorderColor: borderColor,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 4,
    pointHoverBackgroundColor: 'white',
    pointHoverBorderColor: borderColor,
    pointHoverBorderWidth: 2
})

const Container = styled.div`
    height: 100%;
    overflow-y: scroll;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 20px;
`

const GridItem = styled.div`
    padding: 10px;
    background: white;
    box-shadow: 0 1px 3px 0 #cfd8dc;
    ${ props => props.wide && `
        grid-column: 1 / span 2;
    `}
`

class ApplicationDashboard extends React.Component {

    async componentDidMount() {
        const {  search, match: { params: { appId, companyId } } } = this.props
        // if (issues.length) return
        search(companyId, appId)
    }

    render() {
        const { app, appStats } = this.props

        const dateCount = 30
        const defaultStat = {
            orders: 0,
            downloads: 0,
            revenue: 0,
            launches: 0,
            launchesFirstTime: 0,
            visitsSales: 0,
            downloadsSales: 0,
            installsSales: 0,
            issuesAttention: 0,
            issuesTotal: 0,
            feedbackAttention: 0,
            feedbackTotal: 0
        }

        const dates = [...Array(dateCount)].reduce(( result, item, index) => {
            return [ moment().subtract(index, "days"), ...result ]
        }, [])

        const weekends = dates.reduce(( result, date ) => {
            const weekday = date.format('dddd'); // Monday ... Sunday
            const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
            return [ ...result, isWeekend ]
        }, [])

        const labels = dates.reduce(( result, date ) => {
            return [ ...result, date.format("MMM DD") ]
            // return [ ...result, date.format("MMM Do") ]
        }, [])

        const dateIndex = date => date.format("YYYYMMDD")

        // Creates an object containing an array of values for each metric
        // {
        //     labels: ['Feb 12', 'Feb 13', 'Feb 14'],
        //     downloads: [1,2,3,4,5,6],
        //     visits: [1,2,3,4,5,6],
        //     ...
        // }
        const seriesData = dates.reduce(( result, date ) => {
            const stat = appStats[dateIndex(date)] || { }

            // use defaultStat for keys as keys in stat object might be missing
            return Object.keys(defaultStat).reduce((result, key) => {
                if (defaultStat.hasOwnProperty(key)) {
                    const value = stat[key] || 0
                    return { ...result, [key]: [ ...result[key] || [], value ] }
                }
            }, result)
        }, { labels, weekends })


        // OVERVIEW
        const ordersAxis    = axis({ id:'A', label:'Orders',    borderColor: '#6eafd4',   backgroundColor: '#6eafd444' })
        const downloadAxis  = axis({ id:'A', label:'Downloads', borderColor: 'rgb(70, 147, 196)',  backgroundColor: 'rgba(218, 234, 243, 0.6)' })
        const revenueAxis   = axis({ id:'B', label:'Revenue',   borderColor: '#77c379',  backgroundColor: '#77c379', fill:false })

        const metricsOverview = [
            { axis: downloadAxis,   property: 'downloads',   title: 'Downloads',   value: 222,         color: '#4693c4',   difference: -35.37},
            { axis: ordersAxis,     property: 'orders',      title: 'Orders',      value: 345,         color: '#6eafd4',   difference: 24.6},
            { axis: revenueAxis,    property: 'revenue',     title: 'Revenue',     value: '$60.56',    color: '#77c379',   valuePrefix: '$' }
        ]

        // CONVERSIONS
        const visitsSalesAxis    = axis({ id:'A', label:'Visits',    borderColor: 'rgb(70, 147, 196)',   backgroundColor: 'rgba(218, 234, 243, 0.6)' })
        const downloadsSalesAxis  = axis({ id:'A', label:'Downloads', borderColor: 'rgba(75,192,192,1)',  backgroundColor: 'rgba(75,192,192,0.4)' })
        const installsSalesAxis   = axis({ id:'B', label:'Revenue',   borderColor: 'rgba(75,192,192,1)',  backgroundColor: 'rgba(75,192,192,0.4)' })

        const metricsConversions = [
            { axis: visitsSalesAxis,     property: 'visitsSales',    title: 'Visits > Sales',    value: 23,          color: '#4693c4',   difference: 10 },
            { axis: downloadsSalesAxis,  property: 'downloadsSales', title: 'Downloads > Sales', value: '$60.56',    color: '#77c379',   difference: 10 },
            { axis: installsSalesAxis,   property: 'installsSales',  title: 'Installs > Sales',  value: 56,          color: '#6eafd4',   difference: 0 }
        ]

        // LAUNCHES
        const launchesAxis   = axis({ id:'A', label:'Visits',    borderColor: 'rgb(70, 147, 196)',   backgroundColor: '#6eafd4', borderWidth: 0 })
        const firstTimeAxis  = axis({ id:'A', label:'Downloads', borderColor: 'rgba(75,192,192,1)',  backgroundColor: '#4693c4', borderWidth: 0 })

        const metricsAppLaunches = [
            { axis: firstTimeAxis,   property: 'launchesFirstTime',    title: 'First Time',        value: 56,   color: '#6eafd4',   difference: -34 },
            { axis: launchesAxis,    property: 'launches',             title: 'Launches',          value: 23,   color: '#4693c4',   difference: 10 }
        ]

        // ISSUES
        const metricsIssues = [
            { axis: ordersAxis,     property: 'issuesAttention',      title: 'Require Attention', value: 23,    color: '#4693c4',   difference: 10 },
            { axis: downloadAxis,   property: 'issuesTotal',          title: 'Total Received',    value: 56,    color: '#6eafd4',   difference: -34 }
        ]

        // FEEDBACK
        const metricsFeedback = [
            { axis: ordersAxis,     property: 'feedbackAttention',    title: 'Require Attention', value: 23,    color: '#4693c4',   difference: 10 },
            { axis: downloadAxis,   property: 'feedbackTotal',        title: 'Total Received',    value: 56,    color: '#6eafd4',   difference: -34 }
        ]

        return (
            <Container>
                <Grid>
                    <GridItem wide>
                        <DevAntChart {...this.props} options={AppOverviewOptions} showAppInfo height="240" seriesData={seriesData} metrics={metricsOverview} firstDate={dates[0]} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppOverviewOptions} title="Conversions" seriesData={seriesData} metrics={metricsConversions} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="App Launches" type="bar" seriesData={seriesData} metrics={metricsAppLaunches} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Issues" type="bar" seriesData={seriesData} metrics={metricsIssues} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Feedback" type="bar" seriesData={seriesData} metrics={metricsFeedback} />
                    </GridItem>

                    <GridItem>
                        <DevAntChart {...this.props} options={AppLaunchesOptions} title="Users by App Version" type="pie" seriesData={seriesData} metrics={metricsIssues} />
                    </GridItem>
                </Grid>
            </Container>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        app: getSelectedApp(state, props),
        appStats: appStats(state, props)
        // search: getLicensesSearch(state),
        // selectedLicenseCode: getSelectedLicenseCode(state)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId) => dispatch(search(companyId, appId))
    // licensesSearch: (companyId, appId, search) => dispatch(licensesSearch(companyId, appId, search))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDashboard)

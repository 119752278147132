import React from 'react'
import moment from 'moment'
import { Item } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search, getSearch, loadMore, select } from 'actions/licenseTypes'
import { getLicenseTypesForApp, getSelectedLicenseTypeId, getLicenseTypesMoreDataAvailable } from 'selectors/licenseTypes'

import styled from 'styled-components'

import { InfiniteItemsList } from 'InfiniteItemsList'

const licenseTypeName = ({ name }) => ( name && <strong>{name}</strong>)
// const customerName = ({ firstName, lastName }) => (<React.Fragment>{firstName} {lastName}</React.Fragment>)
const createdDate = ({ createdAt }) => {
    const s = moment(createdAt).format("MMM Do, YYYY")
    return s
}

const StyledItemDescription = styled(Item.Description)`
    margin: .4em;
`

const StyledItemMeta = styled(Item.Meta)`
    margin: 0 .4em;
`

const itemRenderer = (licenseType) => (
    <React.Fragment>
        <Item.Header>
            {licenseTypeName(licenseType)}
        </Item.Header>

        <StyledItemMeta style={{float:'right'}}>
            {createdDate(licenseType)}
        </StyledItemMeta>

        <StyledItemMeta>
            {licenseType.licenseTypeId}
        </StyledItemMeta>
    </React.Fragment>
)

const getItemId = (item) => item.licenseTypeId

const InfiniteLicenseTypesList = (props) => (<InfiniteItemsList divided selection style={{margin:0, overflowY: 'auto'}} {...props} />)

const mapStateToProps = (state, props) => ({
    items: getLicenseTypesForApp(state, props),
    selectedItemId: getSelectedLicenseTypeId(state, props),
    moreDataAvailable: getLicenseTypesMoreDataAvailable(state, props),
    getItemId,
    itemRenderer
})

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, search) => dispatch(search(companyId, appId, search)),
    loadMore: (companyId, appId) => dispatch(loadMore(companyId, appId)),
    select: (item) => dispatch(select(item))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfiniteLicenseTypesList)

import React from 'react'

import { connect } from 'react-redux'
import { performAppNav } from 'actions/nav'
import { getSelectedCompany } from 'selectors'

import ContentContainer from 'ContentContainer'
import { StyledButton } from 'StyledButton'

import styled from 'styled-components'

import { ImageSrc } from 'DevAntMethods'

const StyledContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 2em;
`

const InfoContainer = styled.div`
    width: 100%;
    max-width: 600px !important;
`

const CustomerContainer = styled.div`
  display: flex;
  align-items: center;

`
const Avatar = styled.img`
  width: 50px;
  margin: .5em 1em .5em 0;
`
const CustomerName = styled.h1`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  flex: 1;
`
const WebsiteLink = styled.a`
    display: block;
    font-family: monospace;
    text-decoration: none;
    font-size: .9em;
    line-height: 2em;
    color: #628396;
`

const Divider = styled.div`
    height: 70px;
    width: 1px;
    margin: 1em;
    background: linear-gradient(to bottom,#fff 0%,#d6d6d6 20%,#d6d6d6 80%,#fff);
`


const CompanyImage = ({ icon: {imageId, imageExt}, size=64 }) => (
    <Avatar src={ImageSrc(imageId, imageExt, size)} />
)

const CompanyHeader = ({company, editCompanyInfo}) => {
    const { name, website, icon={} } = company

    return (
        <CustomerContainer>
            <CompanyImage icon={icon} size={128} />
            <Divider></Divider>
            <CustomerName>
                {name}
                <WebsiteLink href={website} target="_blank">{website}</WebsiteLink>
            </CustomerName>
            <StyledButton outline onClick={ editCompanyInfo }>Edit</StyledButton>
        </CustomerContainer>
    )
}

const FastSpringHeader = ({company, editFastSpring}) => {
    const { name, website } = company

    return (
        <CustomerContainer>
            <CustomerName>
                FastSpring Integration
            </CustomerName>
            <StyledButton outline onClick={ editFastSpring }>Edit</StyledButton>
        </CustomerContainer>
    )
}

const InfoBanner = ({children}) => (
    <ContentContainer hugContent>
        <StyledContentContainer>
            <InfoContainer>
                {children}
            </InfoContainer>
        </StyledContentContainer>
    </ContentContainer>
)

class CompanySettings extends React.Component {

    editCompanyInfo() {
        const { performAppNav, match: { params: { companyId } } } = this.props
        performAppNav({ companyId, route:'settings/company' })
    }

    editFastSpring() {
        const { performAppNav, match: { params: { companyId } } } = this.props
        performAppNav({ companyId, route:'settings/fastspring' })
    }

    render() {
        const { company } = this.props

        if (company == null) return null

        const { name, website } = company

        return (
            <React.Fragment>
                <InfoBanner>
                    <CompanyHeader company={company} editCompanyInfo={ () => this.editCompanyInfo() }></CompanyHeader>
                </InfoBanner>
                <InfoBanner>
                    <FastSpringHeader company={company} editFastSpring={ ()=> this.editFastSpring() }></FastSpringHeader>
                </InfoBanner>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        company: getSelectedCompany(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySettings)

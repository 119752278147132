import { API } from "aws-amplify";
import { Actions, ListActions } from 'Constants'

export const getSearch = state => state.issues.list.search

export const search = (companyId, appId, search, LastEvaluatedKey) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_ISSUES_LIST, listAction: ListActions.SEARCH, search })

        if (!appId) { return }

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    appId,
                    type: 'issue',
                    search,
                    ...(LastEvaluatedKey && {ExclusiveStartKey : LastEvaluatedKey})
                }
            }

            API.post("activations", "/application/find", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Check search params haven't changed
                const currentSearch = getSearch(getState())
                // if (currentSearch !== search) return

                dispatch({ type: Actions.UI_ISSUES_LIST, listAction: ListActions.ITEMS, items, LastEvaluatedKey })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

export const loadMore = (companyId, appId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const search = state.issues.list.search
        const LastEvaluatedKey = state.issues.list.LastEvaluatedKey

        dispatch(search(companyId, appId, search, LastEvaluatedKey))
    }
}

export const select = (issue) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_ISSUES_SELECT, issue })
    }
}

import React from 'react'
import { Item, Segment, List, Visibility } from 'semantic-ui-react'

import styled from 'styled-components'

const StyledItem = styled(Item)`
    background: ${props => props.selected ? "rgba(184,215,215,.5)" : "white"} !important;

    @media (prefers-color-scheme: dark) {
        background: ${props => props.selected ? "#3637388c" : "#222"} !important;
        color: #82adb9 !important;
    }
`

// pass in search, moreDataAvailable, loadMore, select, selectedItemId=selectedIssueId, items=issues, getItemId(), itemRenderer
export class InfiniteItemsList extends React.Component {

    handleSearch(event) {
        const { search, match: { params: { app: appId, companyId } } } = this.props
        search(companyId, appId, event.target.value)
    }

    loadMoreData() {
        const { moreDataAvailable, loadMore, match: { params: { app: appId, companyId } } } = this.props
        if (!moreDataAvailable) return
        loadMore(companyId, appId, 2)
    }

    selectItem(item) {
        const { select } = this.props
        select(item)
    }

    items() {
        const { selectedItemId, items, moreDataAvailable, getItemId, itemRenderer, ...rest } = this.props

        if (!items) return null

        const count = Object.keys(items).length

        const itemData = Object.values(items).map((item, i) => {
            return {
                ...item,
                selected: selectedItemId === getItemId(item),
                showVisibilityLoader: (i === count - 10 && moreDataAvailable)
            }
        })

        return itemData.map((item, i) => (
            <StyledItem key={getItemId(item)} selected={item.selected}>
                <Item.Content onClick={() => this.selectItem(item)}>

                    {
                        itemRenderer(item, rest)
                    }

                </Item.Content>

                {
                    item.showVisibilityLoader &&
                    <Visibility continuous={false} once={false} onBottomVisible={() => this.loadMoreData()} />
                }

            </StyledItem>
        ))
    }

    render() {
        const { moreDataAvailable } = this.props

        return (
            <List divided selection style={{margin:0, overflowY: 'auto'}}>
                { this.items() }

                { moreDataAvailable &&
                    <Segment basic loading style={{height:'300px'}} />
                }
            </List>
        )
    }
}

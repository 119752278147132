import React from 'react'
import {  Icon, Input } from 'semantic-ui-react'

import { connect } from 'react-redux';
// import { licensesSearch } from './actions'
// import { getLicensesSearch, getSelectedLicenseCode } from './selectors'

import ContentContainer from 'ContentContainer'

import styled from 'styled-components'
// import Spinner from './Spinner'
import { StyledButton } from 'StyledButton'
import { FileDropzone } from 'FileDropzone'

import { Radio } from 'Radio'
import { Form } from 'Form'

const contentMargin = '1.5em';

const ContentHeader = styled.div`
    color: gray;
    padding: ${props => props.margin};
    position: sticky;
    top: 0;
    border-bottom: 1px solid #d2d2d2;
    background: white;
    z-index: 1;
`
const ContentSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    >* {
        flex: 1;
        max-width: 600px;
        margin: 1em;
    }

`

const FormItem = styled.div`
    display: flex;
    align-items: ${ props => props.align || "top" };



    >* {
        flex: 1;
    }
`



const Buttons = styled.span`
    display: flex;
    align-items: center;
    margin: ${contentMargin};

    space {
        flex: 1;
    }

    a {
        margin-left: 2em;
        color: #888888

        :hover {
            color: #4183C4
            cursor: pointer;
        }
    }
`

const Separator = styled.div`
    height: 2em;
    border-bottom: 1px solid #cecece;
    margin-bottom: 2em;
`

class ApplicationReleasesNew extends React.Component {

    render() {
        const props = this.props

        return (
            <ContentContainer>

                { /* HEADER */ }
                <ContentHeader margin={contentMargin}>
                    Add a new version of Glyph Designer
                </ContentHeader>

                { /* RELEASE DETAILS */ }
                <ContentSection>
                    <Form />
                </ContentSection>

                { /* RELEASE FILES */ }
                <h1>Release Files</h1>
                <FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={null} buttonText="Upload .ZIP" description=".ZIP file will be used to generate delta updates."/>
                <FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={null} buttonText="Upload .DMG" description=".DMG file is recommended for the initial download."/>
                <FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={null} buttonText="Upload .dSYM.ZIP" description=".dSYM.zip is necessary for crash report symbolication."/>


                { /* RELEASE NOTES */ }
                <FormItem>
                    <small>
                        Add Release Notes for each localization, using Markdown for text formatting. Learn more about Markdown syntax.
                        Note that {'{{product}}, {{version}}, and {{date}}'} variables may be used to fetch the product name, current short bundle version number and release date.
                    </small>
                </FormItem>
                <FormItem>
                    <textarea></textarea>
                    <textarea></textarea>
                </FormItem>

                { /* BUTTONS */ }
                <Separator></Separator>
                <Buttons>
                    <StyledButton outline>Cancel</StyledButton>
                    <space />
                    <StyledButton green>Save</StyledButton>
                </Buttons>

            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        // search: getLicensesSearch(state),
        // selectedLicenseCode: getSelectedLicenseCode(state)
    }
}

const mapDispatchToProps = dispatch => ({
    // licensesSearch: (companyId, appId, search) => dispatch(licensesSearch(companyId, appId, search))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationReleasesNew)

import React from 'react'
// import { Button, Header, Input} from 'semantic-ui-react'

import { connect } from 'react-redux';
// import { licensesSearch } from './actions'
// import { getLicensesSearch, getSelectedLicenseCode } from './selectors'

import ContentContainer from 'ContentContainer'

// import styled from 'styled-components'
// import Spinner from './Spinner'

class CompanyTeam extends React.Component {

    render() {
        return (
            <ContentContainer padding emptyContent emptyMessage="Team">
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        // search: getLicensesSearch(state),
        // selectedLicenseCode: getSelectedLicenseCode(state)
    }
}

const mapDispatchToProps = dispatch => ({
    // licensesSearch: (companyId, appId, search) => dispatch(licensesSearch(companyId, appId, search))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyTeam)

import { createSelector } from 'reselect'

// LICENSE
export const getLicenses = state => state.licenses.list.items
export const getLicensesLoading = state => state.licenses.list.isLoading
export const getLicensesMoreDataAvailable = state => state.licenses.list.moreDataAvailable
export const getSelectedLicenseId = (state) => state.licenses.selectedId
export const getLicenseBlocked = (state, { activationCode }) => {
    if (!activationCode || activationCode === "") return null
    const license = getLicenses(state)[activationCode]
    if (!license) return null
    return license.blocked
}
export const getSelectedLicenseBlocked = (state) => {
    const code = getSelectedLicenseId(state)
    if (!code) return null
    const licenses = getLicenses(state)
    return licenses[code].blocked;
}
export const getSelectedLicense = createSelector(
    [getSelectedLicenseId, getLicenses],
    (selectedLicenseId, licenses) => {
        return licenses[selectedLicenseId]
    }
)




// CUSTOMER LICENSES
export const showCustomerEditor = (state) => state.licenses.showCustomerEditor
export const getCustomerLicenses = (state) => state.licenses.customerLicenses

export const getCustomerLicense = createSelector(
    [(state, props) => props.activationCode, getCustomerLicenses],
    (activationCode, customerLicenses) => {
        return customerLicenses[activationCode]
    }
)

export const getLicense = createSelector(
    [(state, props) => props.activationCode, getLicenses, getCustomerLicenses],
    (activationCode, licenses, customerLicenses) => {
        return licenses[activationCode] || customerLicenses[activationCode]
    }
)

export const getLicenseTypesById = (state) => state.licenseTypes.licenseTypesById
export const getAppsById = (state) => state.apps.appsById

export const getLicenseTypeForLicense = createSelector(
    [(state, props) => getLicense(state, props), getLicenseTypesById],
    (license, licenseTypesById) => {
        if (!license) return
        const { licenseTypeId } = license
        return licenseTypesById[licenseTypeId]
    }
)

export const getAppForLicense = createSelector(
    [(state, props) => getLicenseTypeForLicense(state, props), getAppsById],
    (licenseType, appsById) => {
        if (!licenseType) return
        const { appId } = licenseType
        return appsById[appId]
    }
)

import { ListActions } from 'Constants'

export const defaultListState = {
    search: "",
    items: {},
    isLoading: true,
    moreDataAvailable: true
}

export const processList = (state, action, getItemId) => {
    switch (action.listAction) {
        case ListActions.SEARCH:
            const reset = action.search === state.search ? {} : defaultListState

            return {
                ...state,
                ...reset,
                search: action.search
            }

        case ListActions.ITEMS:
            return {
                ...state,
                LastEvaluatedKey: action.LastEvaluatedKey,
                isLoading: false,
                moreDataAvailable: action.LastEvaluatedKey !== undefined,
                items: action.items.reduce((result, item) => {
                    return { ...result, [getItemId(item)]: item }
                }, { ...state.items })
            }

        case ListActions.UPDATE: {
            const { license } = action
            return {
                ...state,
                items: {
                    ...state.items,
                    [getItemId(license)]: license
                }
            }
        }

        default:
        return state
    }
}

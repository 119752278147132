import { Actions } from 'Constants'

import { defaultListState, processList } from './InfiniteListReducer'
//
// const randomStat = () => {
//     const downloads = Math.random() * 100 + 40
//     const orders = Math.random() * (downloads/4)
//     const revenue = orders * 10
//
//     return {
//         orders: Math.round(orders),
//         downloads: Math.round(downloads),
//         revenue: Math.round(revenue),
//         launches: Math.round(Math.random() * 10 + 5),
//         launchesFirstTime: Math.round(Math.random() * 4),
//         visitsSales: Math.round(Math.random() * 9),
//         downloadsSales: Math.round(Math.random() * 7),
//         installsSales: Math.round(Math.random() * 5)
//     }
// }
//
//
// const stats = {
//     "20200226" : randomStat(),
//     "20200227" : randomStat(),
//     "20200228" : randomStat(),
//     "20200229" : randomStat(),
//     "20200301" : randomStat(),
//     "20200302" : randomStat(),
//     "20200303" : randomStat(),
//     "20200304" : randomStat(),
//     "20200305" : randomStat(),
//     "20200306" : randomStat(),
//     "20200307" : randomStat(),
//     "20200308" : randomStat(),
//     "20200309" : randomStat(),
//     "20200310" : randomStat(),
//     "20200311" : randomStat(),
//     "20200312" : randomStat(),
//     "20200313" : randomStat(),
//     "20200314" : randomStat(),
//     "20200315" : randomStat(),
//     "20200316" : randomStat(),
//     "20200317" : randomStat(),
//     "20200318" : randomStat(),
//     "20200319" : randomStat(),
//     "20200320" : randomStat(),
//     "20200321" : randomStat(),
//     "20200322" : randomStat(),
//     "20200323" : randomStat(),
//     "20200324" : randomStat(),
//     "20200325" : randomStat(),
//     "20200326" : randomStat(),
//     "20200327" : randomStat(),
//     "20200328" : randomStat(),
//     "20200329" : randomStat(),
//     "20200330" : randomStat(),
// }

const defaultState = {
    isLoading: false,

    statsByAppId: { },

    totalsByAppId: {
        // 400... : {revenue:234, downloads:2}
    }
}

const AppStats = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.UI_STATISTICS_LIST:

            // App specific statistics
            if (action.appId) {
                return {
                    ...state,
                    // LastEvaluatedKey: action.LastEvaluatedKey,
                    isLoading: false,
                    // moreDataAvailable: action.LastEvaluatedKey !== undefined,
                    statsByAppId: action.items.reduce((result, item) => {
                        const [companyId, appId] = item.PK.split('|')
                        const [kind, date] = item.SK.split('|')

                        return { ...result, [appId]: { ...result[appId], [date]: item  } }
                    }, { ...state.statsByAppId })
                }
            }

            // App totals
            else {

                return {
                    ...state,

                    totalsByAppId: action.items.reduce((result, item) => {
                        const { PK, SK, ...rest } = item

                        return Object.keys(rest).reduce((result, key) => {
                            const [appId, statistic] = key.split('|')
                            const appIdStats = result[appId] || {}
                            const value = appIdStats[statistic] || 0
                            const newValue = value + rest[key]

                            return {
                                ...result,
                                [appId] : { ...result[appId], [statistic]:newValue }
                            }
                        }, result)

                    }, {})
                }
            }

        default:
        return state
    }
}

export default AppStats

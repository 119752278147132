import React from "react";
import styled from 'styled-components';

const dotSize = '11px';

const Container = styled.div`
    cursor: pointer;
    user-select: none;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: .4em;
    grid-template-areas:
        "dot text"
        "none total"
        "none difference";
    align-items: center;
    font-weight: 400;
    font-size: 1em;
    color: #263238;
`

const LegendDot = styled.div`
    grid-area: dot;
    width: ${dotSize};
    height: ${dotSize};
    border-radius: 50%;
    margin: 0 .5em;
    border: 2px solid ${ (props) => props.color };
    background: ${ (props) => props.enabled && props.color };
    ${props => !props.enabled && `
        filter:  opacity(0.5)
    `}
`

const LegendText = styled.div`
  grid-area: text;
  font-size: 1.0em;
  font-weight: 400;
  color: ${ (props) => props.enabled ? '#000' : '#aaa' };
`
const LegendTotal = styled.div`
  grid-area: total;
  font-size: 1.9em;
  font-weight: 300;
  color: ${ (props) => props.enabled ? '#000' : '#aaa' };
`
const LegendDifference = styled.div`
  grid-area: difference;
  font-size: .8em;
  color: ${ props => props.up ? '#66bb6a' : '#ef5350' };
  position: relative;
  text-indent: 1em;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;

    ${ props => props.up  && `
        bottom: .4em;
        border-width: 0 .4em .8em;
        border-color: transparent transparent #66bb6a transparent;
    `}

    ${ props => !props.up  && `
        top: .4em;
        border-width: .8em .4em;
        border-color: #ef5350 transparent transparent transparent;
    `}
  }
`

export class LegendItem extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = { enabled: true }
    }

    onClick() {
        const { enabled } = this.state
        const { onClick } = this.props

        this.setState({ enabled: !enabled })
        onClick && onClick()
    }

    render() {
        const { enabled } = this.state
        const { text, value, difference=0, color, valuePrefix="" } = this.props

        return (
            <Container onClick={() => this.onClick()}>
                <LegendDot color={color} enabled={enabled} />
                <LegendText enabled={enabled}>{text}</LegendText>
                <LegendTotal enabled={enabled}>{valuePrefix}{value}</LegendTotal>
                { difference !== 0 &&
                    <LegendDifference up={difference>0}>{difference}%</LegendDifference>
                }
            </Container>
        )
    }

}

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default Legend;

import React from 'react'
import { Button, Form, Input } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
// import { getLicensesSearch, getSelectedLicenseCode } from './selectors'

import ContentContainer from 'ContentContainer'

import styled from 'styled-components'

const StyledContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 2em;
`

const StyledForm = styled(Form)`
    width: 100%;
    max-width: 600px !important;
`

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 2em;
`

const StyledButtons = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px !important;
`
const ButtonSpacer = styled.div`
    flex: 1;
`

class NewApplication extends React.Component {

    updateCompany() {
        console.log('new Company');
    }

    saveCompany() {

    }

    onCancel() {
        const { performAppNav, match: { params: { companyId } } } = this.props
        performAppNav({companyId, route:'applications'})
    }

    render() {

        const { companyName, website } = this.props

        const buttons = (
            <StyledButtonContainer>
                <StyledButtons>
                    <Button size='small' type='submit' basic onClick={ () => this.onCancel() }>Cancel</Button>
                    <ButtonSpacer />
                    <Button size='small' type='submit' color="green" onClick={ () => this.saveCompany() }>Save Changes</Button>
                </StyledButtons>
            </StyledButtonContainer>
        )

        return (
            <ContentContainer buttons={buttons} hugContent>

                <StyledContentContainer>
                    <StyledForm>
                        <Form.Field>
                            <label>Application Name</label>
                            <Input placeholder='Company Name' value={companyName} onChange={ (e) => this.updateCompany(e, 'companyName') } />
                        </Form.Field>

                        <Form.Field>
                            <label>Website Address</label>
                            <input placeholder='Website' value={website} onChange={ (e) => this.updateCompany(e, 'website') } />
                        </Form.Field>

                        <Form.Field>
                            <label>Company Logo</label>
                            <input placeholder='Website' value={website} onChange={ (e) => this.updateCompany(e, 'website') } />
                        </Form.Field>
                    </StyledForm>
                </StyledContentContainer>

            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        // search: getLicensesSearch(state),
        // selectedLicenseCode: getSelectedLicenseCode(state)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewApplication)


// import React from 'react'
//
// import { Button, Divider } from 'semantic-ui-react'
// import styled from 'styled-components'
//
// import { StyledButton } from 'StyledButton'


// const LicenseTypeHeader = ({licenseType, toggleEditor}) => {
//     // const { companyName, firstName, lastName, email } = license
//     // const title = companyName || `${firstName||''} ${lastName||''}`
//     // const desc = companyName ? `${firstName||''} ${lastName||''}` : undefined
//
//     const title = licenseType.name
//     const desc = licenseType.description
//
//     return (
//       <CustomerContainer>
//         <CustomerName>{title}
//           <CustomerEmail>{desc}</CustomerEmail>
//         </CustomerName>
//         <EditButton onClick={() => { toggleEditor(licenseType) }}/>
//         <EnableButton onClick={() => { toggleEditor(licenseType) }}/>
//         <DisableButton onClick={() => { toggleEditor(licenseType) }}/>
//       </CustomerContainer>
//     )
// }


// class LicenseTypeEditor extends React.Component {
//     render() {
//         const { licenseType, toggleEditor, licenseTypeUpdate } = this.props
//
//         if (!licenseType) {
//             return null
//         }
//
//         return (
//             <EditorContainer>
//
//                 <LicenseTypeHeader licenseType={licenseType} toggleEditor={ () => toggleEditor(licenseType) } />
//
//                 <Divider />
// Editor
//                 <Divider />
//
//                 <GenerateKeyButton/>
//
//             </EditorContainer>
//         )
//         // { showEditor &&
//         //     <LicenseEditorCustomer licenseType={licenseType} onCancel={ () => toggleEditor() } onSave={ licenseTypeUpdate } />
//         // }
//     }
// }

// const mapStateToProps = (state, props) => {
//     return {
//         licenseType: getLicenseTypes(state, props)[props.id]
//     }
// }
//
// const mapDispatchToProps = dispatch => ({
//     toggleEditor: (...params) => dispatch(toggleEditor(params))
// })
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(LicenseTypeEditor)



import React from 'react'
import { Button, Card, Dropdown, Form, Radio } from 'semantic-ui-react'
import styled from 'styled-components'

import { connect } from 'react-redux';
import { getLicenseTypes } from 'selectors/licenseTypes'
import { toggleEditor, licenseTypeUpdate } from 'actions/licenseTypes'

import { StyledCheckbox } from 'StyledCheckbox'

import {boundMethod} from 'autobind-decorator'

const Container = styled.div`
    padding: 1em;
`

const ContainerTitle = styled.h3`
    font-weight: 400;
`

const LicenseDurationContainer = styled.div`
    padding-left: 2em;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const FlexSmallColumn = styled.div`
    width: 100px;
`

const FlexExpandingColumn = styled.div`
    flex: 1;
`

class LicenseTypeEditor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            licenseType: null
        }
    }

    componentDidMount() {
        const { licenseType } = this.props

        this.setState({
            licenseType
        })
    }

    @boundMethod
    onChange(e, component) {
        if (component) {
            this.onChangeComponent(e, component)
            return
        }

        const { dataset: { propertyName }, value, type } = e.target
        const { licenseType } = this.state

        if (type === 'checkbox') {
            this.setState({
                licenseType: {
                    ...licenseType,
                    [propertyName] : !licenseType[propertyName]
                }
            })
        }
        else {
            this.setState({
                licenseType: {
                    ...licenseType,
                    [propertyName] : value
                }
            })
        }
    }

    onChangeComponent(e, component) {
        const { 'data-property-name': propertyName,  value } = component
        const { licenseType } = this.state

        this.setState({
            licenseType: {
                ...licenseType,
                [propertyName] : value
            }
        })
    }

    render() {
        const { onCancel, licenseTypeUpdate, toggleEditor } = this.props
        const { licenseType } = this.state

        if (!licenseType) return null

        console.log(licenseType);

        const {
            name="",
            description="",
            enabled=true,

            // Limit the maximum number of activations
            maxActivations,

            // License can be for a limited time or lifetime
            licenseDuration='lifetime', // [lifetime, limited]

            // License will be valid for a period of time
            isValidForPeriod,
            validFor,
            validForUnit,

            // License will be valid until a date
            isValidUntilDate,
            validUntil,

            // License will be valid for certain app versions
            limitAppMinVersion=false,
            validAfterVersion,
            limitAppMaxVersion=false,
            validBeforeVersion,

            // Custom tags, accessible from the SDK
            customTags,

            // License will be valid for beta versions only
            validForBetaOnly
        } = licenseType

        const isLimited = licenseDuration === 'limited'
        const validForUnits = [{key:0, text:'days', value:'days'}, {key:1, text:'months', value:'months'}, {key:2, text:'years', value:'years'}]

        return (
            <Container>
                <Form>
                    <ContainerTitle>
                        Edit License Type
                    </ContainerTitle>

                    { /* NAME / DESCRIPTION */ }
                    <Form.Field><input onChange={ this.onChange } data-property-name='name' value={name} placeholder='Name' /></Form.Field>
                    <Form.Field><input onChange={ this.onChange } data-property-name='description' value={description} placeholder='Description' /></Form.Field>


                    { /* ACTIVATION LIMIT */ }
                    <Form.Field onChange={ this.onChange } data-property-name='maxActivations' value={maxActivations} label='Activation Limit' control='input' type='number' />


                    { /* LICENSE DURATION */ }
                    <Form.Field label="License Duration" />
                    <Form.Field>
                        <Radio onChange={ this.onChange } data-property-name='licenseDuration' checked={!isLimited} label='Lifetime' name='licenseDurationGroup' value='lifetime' />
                    </Form.Field>
                    <Form.Field>
                        <Radio onChange={ this.onChange } data-property-name='licenseDuration' checked={isLimited} label='Limited' name='licenseDurationGroup' value='limited' />
                    </Form.Field>

                    <LicenseDurationContainer>
                        <Flex>
                            <FlexSmallColumn><StyledCheckbox onChange={ this.onChange } data-property-name='isValidForPeriod' checked={isValidForPeriod} label='Valid for' control='input' type='checkbox' disabled={!isLimited} /></FlexSmallColumn>
                            <FlexSmallColumn><Form.Field onChange={ this.onChange } data-property-name='validFor' value={validFor} control='input' type='number' disabled={!(isLimited && isValidForPeriod)} /></FlexSmallColumn>
                            <FlexExpandingColumn><Dropdown onChange={ this.onChange } data-property-name='validForUnit' value={validForUnit} compact selection options={validForUnits} disabled={!(isLimited && isValidForPeriod)} /></FlexExpandingColumn>
                        </Flex>
                        <Flex>
                            <FlexSmallColumn><StyledCheckbox onChange={ this.onChange } data-property-name='isValidUntilDate' checked={isValidUntilDate} label='Valid until' control='input' type='checkbox' disabled={!isLimited} /></FlexSmallColumn>
                            <FlexExpandingColumn><input onChange={ this.onChange } data-property-name='validUntil' value={validUntil} control='input' type='date' disabled={!(isLimited && isValidUntilDate)} /></FlexExpandingColumn>
                        </Flex>
                    </LicenseDurationContainer>

                    { /* APP VERSIONS */ }
                    <Form.Field label="Limit App Versions" />
                    <StyledCheckbox onChange={ this.onChange } data-property-name='limitAppMinVersion' checked={limitAppMinVersion} label='Min Version' control='input' type='checkbox'  />
                    <StyledCheckbox onChange={ this.onChange } data-property-name='limitAppMaxVersion' checked={limitAppMaxVersion} label='Max Version' control='input' type='checkbox' />

                    { /* MULTIPLE PRODUCTS */ }
                    <Form.Field label="License Multiple Products" />
                    <StyledCheckbox onChange={ this.onChange } data-property-name='' checked={false} disabled={true} label='Multi-product' control='input' type='checkbox' />

                    { /* CUSTOM TAGS */ }
                    <Form.Field label="Custom Tags" />
                    <Form.Field><input onChange={ this.onChange } data-property-name='customTags' placeholder='Custom tag can be used to switch app behaviour' value={customTags} /></Form.Field>

                    { /* BETA VERSIONS */ }
                    <Form.Field label="Beta Versions" />
                    <StyledCheckbox onChange={ this.onChange } data-property-name='validForBetaOnly' label='Beta versions only' control='input' type='checkbox' checked={validForBetaOnly} />


                    { /* FORM BUTTONS */ }
                    <Button size='mini' type='submit' color="green" floated="right" onClick={ () => licenseTypeUpdate(licenseType) }>Save</Button>
                    <Button size='mini' type='submit' floated="right" onClick={ () => toggleEditor() }>Cancel</Button>
                </Form>
            </Container>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        licenseType: getLicenseTypes(state, props)[props.id]
    }
}

const mapDispatchToProps = dispatch => ({
    licenseTypeUpdate: (...params) => dispatch(licenseTypeUpdate(...params)),
    toggleEditor: (...params) => dispatch(toggleEditor(...params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseTypeEditor)

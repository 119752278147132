import { Actions } from 'Constants'

const defaultState = {
    isLoading: true,
    companiesById: {},

    isLoadingCompanyData: true
}

const Data = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.COMPANIES: {
            const { isLoading = false, companies = [] } = action.payload
            const companiesById = isLoading ? [] : companies.reduce((result, company) => {
                return { ...result, [company.companyId]:company };
            }, state.companiesById)

            return {
                ...state,
                isLoading,
                companiesById
            }
        }
        case Actions.COMPANY_DATA: {
            const { isLoading = false } = action.payload
            return {
                ...state,
                isLoadingCompanyData: isLoading
            }
        }
        default:
        return state
    }
}

export default Data

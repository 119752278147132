import React from 'react'

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
import { search as appStatsSearch } from 'actions/appStats'
import { getAppsLoading } from 'selectors'
import styled from 'styled-components'

import { StyledButton } from 'StyledButton'

import ContentContainer from 'ContentContainer'
import AppList from './AppList'

import { Icon } from 'semantic-ui-react'

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
`
const StyledFlex1 = styled.div`
    flex: 1;
`

const SegmentedControl = styled.div`
    display: flex;
    white-space: nowrap;
    font-weight: 400;
    font-size: .8em;
    color: #5a5a5a;

    div {
        border-style: solid;
        border-color: #d6d6d6;
        border-width: 0px;
        cursor: pointer;
        transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;

        :first-child {
            border-width: 1px 0px 1px 1px;
            border-radius: .5em 0 0 .5em;
        }

        :last-child {
            border-width: 1px 1px 1px 0px;
            border-radius: 0 .5em .5em 0;
        }

        :not(:first-child):not(:last-child) {
            border-width: 1px 0px 1px 0px;
        }

        :not(:first-child) {
            border-left-width: 1px !important;
        }

        :hover {
            background:#9c9c9c;
            color: white;
            border-color: #9c9c9c;
        }
    }
`

const SegmentedItem = styled.div`
    flex: 1;
    padding: .4em 1em;
    background: ${props => props.selected ? "#ececec" : "none"};
    cursor: pointer;
`

class Applications extends React.Component {
    async componentDidMount() {
        const {  appStatsSearch, match: { params: { appId, companyId } } } = this.props
        appStatsSearch(companyId)
    }

    newApplication() {
        const { performAppNav, match: { params: { companyId } } } = this.props
        performAppNav({companyId, route:'newApplication'})
    }

    render() {
        const { isLoading } = this.props

        const header = (
            <StyledHeader>
                <StyledButton mini onClick={() => { this.newApplication() }}>
                     <Icon name='add' />
                    Add Application
                </StyledButton>
                <StyledFlex1 />
                <SegmentedControl>
                    <SegmentedItem>Week</SegmentedItem>
                    <SegmentedItem selected>Month</SegmentedItem>
                    <SegmentedItem>Year</SegmentedItem>
                </SegmentedControl>
            </StyledHeader>
        )

        return (
            <ContentContainer padding header={header} loading={isLoading}>
                <AppList {...this.props} />
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        isLoading: getAppsLoading(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    appStatsSearch: (companyId, appId) => dispatch(appStatsSearch(companyId, appId)),
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Applications)

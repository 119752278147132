import { Actions } from 'Constants'

const defaultState = {
    isLoading: true,
    appsById: {},
    appLicenseData: undefined
}

const Data = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.APPS:
            const { isLoading = false, apps = [] } = action.payload

            return {
                ...state,
                isLoading,
                appsById: apps.reduce((result, app) => {
                    return { ...result, [app.appId]: app }
                }, state.appsById)
            }

        case Actions.UI_APPLICATION_LICENSE_DATA: {
            return {
                ...state,
                loadingAppLicenseData: action.loading,
                appLicenseData: action.appLicenseData
            }
        }

        default:
        return state
    }
}

export default Data

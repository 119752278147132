import { Actions } from 'Constants'

import { defaultListState, processList } from './InfiniteListReducer'

const defaultState = {
    list: defaultListState,
    selectedId: undefined
}

const feedbackId = (feedback) => { return feedback.feedbackId }

const Feedback = (state = defaultState, action) => {
    switch (action.type) {

        case Actions.UI_FEEDBACK_LIST:
            return {
                ...state,
                list: processList(state.list, action, feedbackId)
            }

        case Actions.UI_CHANGE_COMPANY:
            return defaultState

        case Actions.UI_CHANGE_APP:
            return defaultState

        case Actions.UI_FEEDBACK_SELECT:
            return {
                ...state,
                selectedId: action.feedback.feedbackId
            }

        default:
        return state
    }
}

export default Feedback

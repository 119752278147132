import React from 'react'
import { List, Item } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
import { getReleases } from 'selectors'
import styled from 'styled-components'

import { ImageSrc } from 'DevAntMethods'

const makeComparator = (key, order='asc') => {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

        const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (aVal > bVal) comparison = 1;
        if (aVal < bVal) comparison = -1;

        return order === 'desc' ? (comparison * -1) : comparison
    }
}

const StyledGrid = styled.div`
    display: grid;
    align-items: top;
    /* grid-template-rows: 2em auto; */
`

const StyledGridRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 6fr 3fr 4fr 2fr 2fr;
    column-gap: 1em;
    align-items: center;
    padding: .5em 0em;
    color: #565656;

    /* Make sure child element content squishes, otherwise it affects layout and throws column alignment out */
    > * {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    /* Column styles */
    status {
        display: inline-block;
        white-space: nowrap;
        width: 6em;
    }
    releaseName {
        color: rgb(25, 100, 201);
        font-weight: bold;
    }
    download {
        color: rgb(75, 120, 190);
        font-weight: bold;
    }

    /* Add some spacing left/right of the table */
    >:first-child { margin-left:1em; }
    >:last-child { margin-right:1em; }

    /* Header row style */
    &:first-child {
        titleText {
            font-size: .9em;
        }

        color: #9a9a9a;
        position: sticky;
        top: 0;
        background: white;
        border-bottom: 1px solid #ececec;
        white-space: nowrap;
    }

    /* Row styles */
    &:not(:first-child) {
        border-bottom: 1px solid #ececec;
    }
    &:hover:not(:first-child) {
        background: rgba(0, 0, 0, 0.03);
        cursor: pointer;
    }
`

const ReleaseName = styled.div`
    flex: 1fr;
    font-weight: 300;
    font-size: 1.5em;
    color: #446f7b;
`

const StatusColumn = styled.div`
    text-align: center;
`
const ReleaseNameColumn = styled.div`
`
const InfoColumn = styled.div`
    text-align: right;
`
const DownloadsColumn = styled.div`
`

const VersionColumn = styled.div`
    text-align: center;
`

const ReleaseStatusBackgroundColors = {
    'live' : '#cae9b0',
    'online' : '#cae9b0',
    'offline' : '#dfe0e2'
}

const ReleaseStatusColors = {
    'live' : '#386f3b',
    'online' : '#6f895d',
    'offline' : '#5a5f64'
}

const ReleaseStatus = styled.div`
    display: inline-block;
    background: ${ ({status}) => ReleaseStatusBackgroundColors[status] };
    color: ${ ({status}) => ReleaseStatusColors[status] };
    padding: .3em 1em;
    border-radius: .2em;
    font-size: .8em;
    font-weight: 600;
    width: 100%;
    text-align: center;
`

class ReleasesList extends React.Component {

    selectRelease(release) {
        // const { performAppNav, match: { params: { companyId } } } = this.props
        // const { appId } = app
        // performAppNav({companyId, appId})
        console.log(release);
    }

    releaseItems() {
        const { releases } = this.props

        return releases.sort(makeComparator('name')).map(release =>
            <StyledGridRow key={release.releaseId} onClick={ () => this.selectRelease(release) }>
                <status><ReleaseStatus status='offline'>Offline β</ReleaseStatus></status>
                <releaseName>Glyph Designer 314</releaseName>
                <VersionColumn>2.2 (314)</VersionColumn>
                <InfoColumn>Jul 17, 2019 20:21:47</InfoColumn>
                <download>ZIP</download>
                <InfoColumn>Upload dSYM</InfoColumn>
            </StyledGridRow>
        )
    }

    // <status><ReleaseStatus status='live'>Live</ReleaseStatus></status>
    // <status><ReleaseStatus status='online'>Online</ReleaseStatus></status>


    render() {

        return (
            <StyledGrid>
                <StyledGridRow>
                    <status><titleText>Status</titleText></status>
                    <titleText>Release Title</titleText>
                    <VersionColumn><titleText>Version #</titleText></VersionColumn>
                    <InfoColumn><titleText>Release Date</titleText></InfoColumn>
                    <DownloadsColumn><titleText>Download</titleText></DownloadsColumn>
                    <InfoColumn></InfoColumn>
                </StyledGridRow>
                { this.releaseItems() }
            </StyledGrid>
        )
    }
}

const mapStateToProps = (state, props) => ({
    releases: getReleases(state, props)
})

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleasesList)

import React from 'react'
// import { Button, Header, Input} from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search, createNewLicenseType, toggleEditor } from 'actions/licenseTypes'
import { getSelectedLicenseTypeId, getLicenseTypesForApp, getLicenseTypesLoading, showEditor } from 'selectors/licenseTypes'

import ContentContainer from 'ContentContainer'

import LicenseTypesList from './LicenseTypesList'
import LicenseTypeEditor from './LicenseTypeEditor'
import LicenseTypeViewer from './LicenseTypeViewer'

import styled from 'styled-components'

import { StyledButton } from 'StyledButton'

const ContentFlex = styled.div`
    display: flex;
    height: 100%;
`
const LicensesContentLeft = styled.div`
    flex: 1;
    overflow: scroll;
    border-right: 1px solid #d8d8d8 !important;

    @media (prefers-color-scheme: dark) {
        border-right: 1px solid #3e3e3e !important;
    }
`
const LicensesContentRight = styled.div`
    flex: 1;
    overflow: scroll;
    background: rgb(251, 251, 251);

    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
        background-color: #222 !important;
    }

`

class LicenseTypes extends React.Component {

    async componentDidMount() {
        const { licenseTypes, search, match: { params: { appId, companyId } } } = this.props
        if (licenseTypes.length) return
        search(companyId, appId)
    }

    newLicenseType() {
        const { createNewLicenseType, match: { params: { appId, companyId } } } = this.props
        createNewLicenseType(companyId, appId)
    }

    render() {
        const { selectedLicenseTypeId, isLoading, licenseTypes, showEditor } = this.props

        const header = (
            <React.Fragment>
                Move License Types/Generators to Company level - support multiple products?
                <StyledButton size='mini' floated='right' icon='add' content="New License Type" onClick={() => { this.newLicenseType() }}/>
            </React.Fragment>
        )

        const DetailComponent = showEditor ? LicenseTypeEditor : LicenseTypeViewer

        return (
            <ContentContainer padding loading={isLoading} emptyContent={Object.keys(licenseTypes).length === 0} header={header}>
                <ContentFlex>
                    <LicensesContentLeft>
                        <LicenseTypesList {...this.props} />
                    </LicensesContentLeft>

                    <LicensesContentRight>
                        <DetailComponent {...this.props} id={selectedLicenseTypeId} />
                    </LicensesContentRight>
                </ContentFlex>
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        state,
        selectedLicenseTypeId: getSelectedLicenseTypeId(state),
        licenseTypes: getLicenseTypesForApp(state, props),
        isLoading: getLicenseTypesLoading(state, props),
        showEditor: showEditor(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, searchValue) => dispatch(search(companyId, appId, searchValue)),
    createNewLicenseType: (...params) => dispatch(createNewLicenseType(...params)),
    toggleEditor: (...params) => dispatch(toggleEditor(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseTypes)

import { createSelector } from 'reselect'

import { getSelectedAppId } from './index'

// LICENSE TYPES
export const getLicenseTypes = state => state.licenseTypes.licenseTypesById

export const getLicenseTypesForApp = createSelector(
    [getSelectedAppId, getLicenseTypes],
    (appId, licenseTypes) => {
        return Object.keys(licenseTypes).reduce((result, licenseTypeId) => {
            const licenseType = licenseTypes[licenseTypeId]
            if (licenseType.appId !== appId) return result
            return {
                ...result,
                [licenseTypeId]: licenseType
            }
        }, {})
    }
)

// export const getLicenseTypesLoading = state => state.licenseTypes.list.isLoading
export const getLicenseTypesLoading = state => state.licenseTypes.isLoading
export const getLicenseTypesMoreDataAvailable = state => state.licenseTypes.list.moreDataAvailable
export const getSelectedLicenseTypeId = (state) => state.licenseTypes.selectedId
export const getSelectedLicenseType = createSelector(
    [getSelectedLicenseTypeId, getLicenseTypes],
    (licenseTypeId, licenseTypes) => {
        return licenseTypes[licenseTypeId]
    }
)

//
export const showEditor = (state) => state.licenseTypes.showEditor
// export const getCustomerLicenses = (state) => state.licenses.customerLicenses

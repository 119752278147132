import { push } from 'connected-react-router'
import { Actions } from 'Constants'

export const performAppNav = (nav = {}) => {
    return async (dispatch, getState) => {
        const { companyId, appId, route } = nav

        const companyPath = companyId ? `/${companyId}` : `/`
        const appPath = appId ? `/applications/${appId}` : ``
        const sectionPath = (() => {
            if (route) return `/${route}`
            if (!appId && companyId) return '/applications'
            return ''
        })()

        dispatch(push(`${companyPath}${appPath}${sectionPath}`))
        dispatch({ type: Actions.UI_CHANGE_COMPANY, companyId })
        dispatch({ type: Actions.UI_CHANGE_APP, appId })
        dispatch({ type: Actions.UI_CHANGE_MODE, route })
    }
}

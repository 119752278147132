import React from 'react'
import moment from 'moment'
import { Item } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search, getSearch, loadMore, select } from 'actions/issues'
import { getIssues, getSelectedIssueId, getIssuesMoreDataAvailable } from 'selectors/issues'

import styled from 'styled-components'

import { InfiniteItemsList } from 'InfiniteItemsList'

const issueName = ({ message }) => ( message && <strong>{message}</strong>)
const customerName = ({ firstName, lastName }) => (<React.Fragment>{firstName} {lastName}</React.Fragment>)
const createdDate = ({ createdAt }) => {
    const s = moment(createdAt).format("MMM Do, YYYY")
    return s
}

const StyledItemDescription = styled(Item.Description)`
    margin: .4em;
`

const StyledItemMeta = styled(Item.Meta)`
    margin: 0 .4em;
`

const itemRenderer = (issue) => (
    <React.Fragment>
        <Item.Header>
            {issueName(issue)}
        </Item.Header>

        <StyledItemMeta style={{float:'right'}}>
            {createdDate(issue)}
        </StyledItemMeta>

        <StyledItemMeta>
            {issue.issueId}
        </StyledItemMeta>
    </React.Fragment>
)

const getItemId = (item) => item.issueId

const InfiniteIssuesList = (props) => (<InfiniteItemsList divided selection style={{margin:0, overflowY: 'auto'}} {...props} />)

const mapStateToProps = (state, props) => ({
    items: getIssues(state, props),
    selectedItemId: getSelectedIssueId(state, props),
    moreDataAvailable: getIssuesMoreDataAvailable(state, props),
    getItemId,
    itemRenderer
})

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, search) => dispatch(search(companyId, appId, search)),
    loadMore: (companyId, appId) => dispatch(loadMore(companyId, appId)),
    select: (item) => dispatch(select(item))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfiniteIssuesList)

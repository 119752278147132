import crypto from 'crypto'
import React from 'react'
// import moment from 'moment'
import { Button, Divider } from 'semantic-ui-react'
import styled from 'styled-components'

import { connect } from 'react-redux';
import { toggleEditor, getFastspringLink } from 'actions/paymentProcessors'
import { getPaymentProcessors, showEditor, fsLinkToken, loadingFSLink } from 'selectors/paymentProcessors'

import ContentContainer from 'ContentContainer'

// import LicenseCard from './LicenseCard'
// import LicenseEditorCustomer from './LicenseEditorCustomer'

const EditorContainer = styled.div`
    overflow-y: scroll;
    padding: 20px !important;

`

// const FormattedDate = ({ date }) => { return moment(date, "YYYYMMDDHHmmss").format("MMM Do, YYYY") }
const EditButton = (props) => <Button basic icon='edit outline' {...props} />

const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
`
const Avatar = styled.img`
  border-radius: 50%;
  width: 50px;
  margin: .5em 1em .5em 0;
`
const CustomerName = styled.h1`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  flex: 1;
`
const CustomerEmail = styled.div`
  color: gray;
  font-size: .8em;
`
const PaymentProcessorHeader = ({paymentProcessor, toggleEditor}) => {
    // const { companyName, firstName, lastName, email } = license
    // const title = companyName || `${firstName||''} ${lastName||''}`
    // const desc = companyName ? `${firstName||''} ${lastName||''}` : undefined

    const title = paymentProcessor.name
    const desc = "desc"

    return (
      <CustomerContainer>
        <CustomerName>{title}
          <CustomerEmail>{desc}</CustomerEmail>
        </CustomerName>
        <EditButton onClick={() => { toggleEditor(paymentProcessor) }}/>
      </CustomerContainer>
    )
}

const formattedLicenseCount = (count) => `${count} License${ count > 1 ? 's' : '' }`

class PaymentProcessorsEditor extends React.Component {

    componentDidMount() {

    }

    onGetFSLink() {
        const { getFastspringLink, company, app, paymentProcessor } = this.props

        const { companyId } = company
        const { appId } = app
        const { paymentProcessorId } = paymentProcessor

        getFastspringLink(companyId, appId, paymentProcessorId)
    }

    render() {
        const { paymentProcessor, showEditor, toggleEditor, paymentProcessorUpdate, fsLinkToken, loadingFSLink } = this.props

        if (!paymentProcessor) {
            return null
        }

        const GetLinkButton = (<Button onClick={() => this.onGetFSLink()}>Get FS License Generator Link</Button>)

        return (
            <EditorContainer>
                { !showEditor &&
                    <PaymentProcessorHeader paymentProcessor={paymentProcessor} toggleEditor={ () => toggleEditor(paymentProcessor) } />
                }



                <ContentContainer emptyContent={fsLinkToken === undefined} emptyMessage={GetLinkButton} loading={loadingFSLink}>
                {fsLinkToken}
                </ContentContainer>

            </EditorContainer>
        )
        // { showEditor &&
        //     <LicenseEditorCustomer licenseType={licenseType} onCancel={ () => toggleEditor() } onSave={ licenseTypeUpdate } />
        // }
    }
}

const mapStateToProps = (state, props) => {
    return {
        paymentProcessor: getPaymentProcessors(state, props)[props.id],
        showEditor: showEditor(state, props),
        fsLinkToken: fsLinkToken(state, props),
        loadingFSLink: loadingFSLink(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    toggleEditor: (...params) => dispatch(toggleEditor(params)),
    getFastspringLink: (companyId, appId, paymentProcessorId) => dispatch(getFastspringLink(companyId, appId, paymentProcessorId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentProcessorsEditor)

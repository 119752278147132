import React from 'react'
// import { Button, Header, Input } from 'semantic-ui-react'

import { connect } from 'react-redux';
// import { licensesSearch } from './actions'
// import { getLicensesSearch, getSelectedLicenseCode } from './selectors'

import { performAppNav } from 'actions/nav'
import ContentContainer from 'ContentContainer'

import styled from 'styled-components'
// import Spinner from './Spinner'
import { StyledButton } from 'StyledButton'
import { Icon } from 'semantic-ui-react'

import ReleasesList from './ApplicationReleasesList'

const contentMargin = '1.5em';

const InfoBlock = styled.span`
    display: block;
    color: gray;
    margin: ${contentMargin};
`

const CurrentReleaseBlock = styled.span`
    display: block;
    background: #f7f7f7;
    margin: ${contentMargin};
    padding: 1.5em;
    font-size: .9em;

    info {
        font-weight: bold;
        display: block;
        padding: 0 0 .6em 0;

        green {
            color: #6eb345;
        }
    }

    a {
        display: block;
        font-size: .9em;
    }
`

const Buttons = styled.span`
    display: flex;
    align-items: center;
    margin: ${contentMargin};

    space {
        flex: 1;
    }

    a {
        margin-left: 2em;
        color: #888888

        :hover {
            color: #4183C4
            cursor: pointer;
        }
    }
`

class ApplicationReleases extends React.Component {

    newVersion() {
        const { performAppNav, match: { params: { companyId, appId } } } = this.props
        performAppNav({ companyId, appId, route:'releases/new' })
    }

    render() {
        const props = this.props

        return (
            <ContentContainer padding>
                <InfoBlock>
                    Everything you may need for releasing your app versions and delivering them to users can be found here.
                </InfoBlock>
                <CurrentReleaseBlock>
                    <info>
                        Current <green>LIVE</green> version is Glyph Designer 314
                    </info>
                    <a src="https://dl.devmate.com/com.71squared.glyphdesigner2/GlyphDesigner.zip">
                        https://dl.devmate.com/com.71squared.glyphdesigner2/GlyphDesigner.zip
                    </a>
                </CurrentReleaseBlock>
                <Buttons>
                    <StyledButton green onClick={ () => { this.newVersion() } } >Add New Application Version</StyledButton>
                    <a>
                        <Icon name='info circle' />Setup Instructions & XML URL's
                    </a>
                    <space />
                    <StyledButton outline turquoise>dSYM Management</StyledButton>
                </Buttons>
                <ReleasesList { ...props } />
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        // search: getLicensesSearch(state),
        // selectedLicenseCode: getSelectedLicenseCode(state)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
    // licensesSearch: (companyId, appId, search) => dispatch(licensesSearch(companyId, appId, search))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationReleases)

import React from 'react'
import { Button, Card, Form } from 'semantic-ui-react'
// import styled from 'styled-components'

import { connect } from 'react-redux';
import { toggleCustomerEditor, licenseUpdate } from 'actions/licenses'
// import { getSelectedLicense } from './selectors'

class LicenseEditorCustomer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            license: null
        }
    }

    componentDidMount() {
        const { license } = this.props
        this.setState({
            license
        })
    }

    updateLicense(event, name) {
        const { license } = this.state
        this.setState({
            license: {
                ...license,
                [name] : event.target.value
            }
        })
    }

    render() {
        const { onCancel, licenseUpdate } = this.props
        const { license } = this.state

        if (!license) return null

        const { companyName="", firstName="", lastName="", email="", name="" } = license

        return (
            <Card fluid>
                <Card.Content>
                    <Form>
                        <Form.Field><input placeholder='Company Name' value={companyName} onChange={ (e) => this.updateLicense(e, 'companyName') } /></Form.Field>
                        <Form.Field><input placeholder='Name' value={name} onChange={ (e) => this.updateLicense(e, 'name') } /></Form.Field>
                        <Form.Field><input placeholder='First Name' value={firstName} onChange={ (e) => this.updateLicense(e, 'firstName') } /></Form.Field>
                        <Form.Field><input placeholder='Last Name' value={lastName} onChange={ (e) => this.updateLicense(e, 'lastName') } /></Form.Field>
                        <Form.Field><input placeholder='Email' value={email} onChange={ (e) => this.updateLicense(e, 'email') } /></Form.Field>
                    </Form>
                </Card.Content>

                <Card.Content extra>
                    <Button size='mini' type='submit' color="green" floated="right" onClick={ () => licenseUpdate(license) }>Save</Button>
                    <Button size='mini' type='submit' floated="right" onClick={ onCancel }>Cancel</Button>
                </Card.Content>
            </Card>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({
    toggleCustomerEditor: () => dispatch(toggleCustomerEditor()),
    licenseUpdate: (...params) => dispatch(licenseUpdate(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseEditorCustomer)

import React, { Component, useState } from 'react'
import { Popup, Icon, List } from 'semantic-ui-react'

import Amplify from 'aws-amplify'

import { connect } from 'react-redux';
import { performAppNav } from './actions/nav'
import { getSelectedCompany, getSelectedApp } from './selectors'
import styled from 'styled-components'

const StyledUserButton = styled.button`
    border: none;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`

const StyledUserIcon = styled(Icon)`
    color: #446f7b;
`

const StyledPopup = styled(Popup)`
    padding: 0 !important;
`

const MenuItem = styled(List.Item)`
    padding: 1em 1em !important;
    border-radius: 0 !important;
`

const StyledMenuIcon = styled(Icon)`
    margin: 0 .5em !important;
`

const PopupExampleEventsEnabled = ({onProfile, onNotification, onLogout}) => {

  const [open, setOpen] = useState(false)

  return (
    <StyledPopup
      content={
            <List selection>
                <MenuItem onClick={onProfile}><StyledMenuIcon name="address card outline"/>Profile</MenuItem>
                <MenuItem onClick={onNotification}><StyledMenuIcon name="bell outline"/>Notifications</MenuItem>
                <MenuItem onClick={onLogout}><StyledMenuIcon name="sign-out"/>Log Out</MenuItem>
            </List>
      }
      on='click'
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      position="bottom right"
      trigger={
          <StyledUserButton>
              <StyledUserIcon size='big' name='user circle'></StyledUserIcon>
          </StyledUserButton>
      }
    />
  )
}



const StyledHeader = styled.div`
    display: flex !important;
    align-items: center;
    padding: .8em;
`

const StyledAppIcon = styled.img`
    width: 64px;
    height: 64px;
    flex: 1;
    margin: 0 1em 0 .5em;
`

const StyledLocation = styled.div`
    flex: 1;
    font-weight: 300;
    font-size: 1.4em;
    color: #446f7b;
    text-align: center;

    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
    }
`


const StyledIcon = styled(Icon)`
    color: #198378 !important;
    margin-right: .8em !important;
`

class AppHeaderContent extends React.Component {

    onProfile() {
        const { performAppNav } = this.props
        performAppNav()
    }

    onNotification() {
        const { performAppNav } = this.props
        performAppNav()
    }

    onLogout() {
        Amplify.Auth.signOut()
    }

    render() {
        const { company, app,  match: { params: { companyId, appId, mode, subMode } } } = this.props

        const name = (() => {
            // root level
            if (!company) return "Companies"

            const modeMap = {
                "applications" : "Applications",
                "licenses" : "Customers",
                "team" : "Team",
                "billing" : "Billing",
                "settings" : "Settings",
                "releases" : "Release Management",
                "issues" : "Issues",
                "feedback" : "Feedback"
            }

            const subModeMap = {
                "company" : "Company Details",
                "fastspring" : "FastSpring Subscriptions",
                "appDetails" : "Application Details",
                "storeURL" : "Store URL",
                "licenseTypes" : "License Types",
                "paymentProcessors": "License Generators",
                "new": "Add new version"
            }

            const appTitle = app ? ` / ${app.name}` : ''
            const modeTitle = mode ? ` / ${modeMap[mode]}` : ''
            const subModeTitle = subMode ? ` / ${subModeMap[subMode]}` : ''

            return `${company.name}${appTitle}${modeTitle}${subModeTitle}`
        })()

        return (
            <StyledHeader>
                <StyledLocation>{name}</StyledLocation>
                <PopupExampleEventsEnabled
                    onProfile={() => {this.onProfile()}}
                    onNotification={() => {this.onNotification()}}
                    onLogout={() => {this.onLogout()}}
                />
            </StyledHeader>
        )
    }
}

const mapStateToProps = (state, props) => ({
    company: getSelectedCompany(state, props),
    app: getSelectedApp(state, props)
})

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeaderContent)

import React from 'react'
import { Button, Input } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search, getSearch } from 'actions/licenses'
import { getSelectedLicenseId, getLicenses, getLicensesLoading } from 'selectors/licenses'

import ContentContainer from 'ContentContainer'
import LicensesList from './LicensesList'
import LicenseEditor from './LicenseEditor'

import styled from 'styled-components'

const LicensesContentLeft = styled.div`
    flex: 50%;
    overflow: scroll;
    border-right: 1px solid #d8d8d8 !important;

    @media (prefers-color-scheme: dark) {
        border-right: 1px solid #3e3e3e !important;
    }
`
const LicensesContentRight = styled.div`
    flex: 50%;
    overflow: scroll;
    background: rgb(251, 251, 251);

    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
        background-color: #222 !important;
    }
`
const ContentFlex = styled.div`
    display: flex;
    height: 100%;
`

const StyledInput = styled(Input)`
    @media (prefers-color-scheme: dark) {
        input[type="text"], textarea {
            background-color : #2f2f2f !important;
            color: red;
        }
        .icon {
            color:white;
        }
    }
`

class Licenses extends React.Component {

    async componentDidMount() {
        const { licenses, search, match: { params: { appId, companyId } } } = this.props
        if (licenses.length) return
        search(companyId, appId)
    }

    newCustomer() {
        console.log('new Customer');
    }

    handleSearch(event) {
        const { search, match: { params: { appId, companyId } } } = this.props
        search(companyId, appId, event.target.value)
    }

    render() {
        const { searchValue, selectedLicenseId, isLoading, licenses } = this.props

        const header = (
            <React.Fragment>
                <Button size='mini' basic floated='right' icon='add' content="New Customer" onClick={() => { this.newCustomer() }}/>
                <StyledInput size='mini' icon='search' placeholder='Search...' value={searchValue} onChange={(event) => this.handleSearch(event)} />
            </React.Fragment>
        )

        return (
            <ContentContainer padding header={header} loading={isLoading} emptyContent={Object.keys(licenses).length === 0}>
                <ContentFlex>
                    <LicensesContentLeft>
                        <LicensesList {...this.props} />
                    </LicensesContentLeft>

                    <LicensesContentRight>
                        <LicenseEditor {...this.props} activationCode={selectedLicenseId} />
                    </LicensesContentRight>
                </ContentFlex>
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        selectedLicenseId: getSelectedLicenseId(state),
        searchValue: getSearch(state, props),
        licenses: getLicenses(state, props),
        isLoading: getLicensesLoading(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, searchValue) => dispatch(search(companyId, appId, searchValue))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Licenses)

import React from 'react'
import moment from 'moment'
import { Item, Label } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search, getSearch, licensesPage, licensesSelect } from 'actions/licenses'
import { getSelectedLicenseId, getLicenses, getLicensesMoreDataAvailable } from 'selectors/licenses'

import styled from 'styled-components'

import Gravatar from 'Gravatar'

import { InfiniteItemsList } from 'InfiniteItemsList'

const companyName = ({ companyName }) => ( companyName && <strong>{companyName}</strong>)
const customerName = ({ firstName, lastName, name }) => (<React.Fragment>{name} {firstName} {lastName}</React.Fragment>)
const orderDate = ({ orderDate }) => {
    const s = moment(orderDate, "YYYYMMDDHHmmss").format("MMM Do, YYYY")
    return s
}

const StyledItemHeader = styled(Item.Header)`
    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
    }
`

const StyledItemDescription = styled(Item.Description)`
    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
    }
`

const StyledItemMeta = styled(Item.Meta)`
    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
    }
`

const StyledLabel = styled(Label)`
    background-color: white !important;
    color: rgb(158, 158, 158) !important;
    border: 1px solid #ddd !important;

    @media (prefers-color-scheme: dark) {
        border: none !important;
        background-color: #3e3e3e !important;
        color: rgb(158, 158, 158) !important;
    }
`

const itemRenderer = (license) => (
    <React.Fragment>
        <StyledLabel icon='copy' style={{float:'right'}} content={license.activationCode} />

        <StyledItemHeader>
            {/* <Gravatar email={license.email} /> */}
            {companyName(license)} {customerName(license)}
        </StyledItemHeader>

        <StyledItemDescription>
            {license.email}
        </StyledItemDescription>

        <StyledItemMeta style={{float:'right'}}>
            {orderDate(license)}
        </StyledItemMeta>

        <StyledItemMeta>
            {license.orderId}
        </StyledItemMeta>
    </React.Fragment>
)

const getItemId = (item) => item.activationCode

const InfiniteLicensesList = (props) => (<InfiniteItemsList divided selection style={{margin:0, overflowY: 'auto'}} {...props} />)

const mapStateToProps = (state, props) => ({
    items: getLicenses(state, props),
    selectedItemId: getSelectedLicenseId(state, props),
    moreDataAvailable: getLicensesMoreDataAvailable(state, props),
    getItemId,
    itemRenderer
})

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, search) => dispatch(search(companyId, appId, search)),
    loadMore: (companyId, appId) => dispatch(licensesPage(companyId, appId)),
    select: (item) => dispatch(licensesSelect(item))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfiniteLicensesList)

import React from 'react'

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
import { getCompanies } from 'selectors'

import { Loading, Nav } from './Nav'

class NavCompanies extends React.Component {
    selectCompany(company) {
        const { performAppNav } = this.props
        const { companyId } = company
        performAppNav({ companyId, route:'applications' })
    }

    render() {
        const { performAppNav, companies, match: { params: { mode, subMode } } } = this.props

        if (!companies) return (<Loading />)

        return (
            <Nav items={companies} selectItem={(item) => this.selectCompany(item)} />
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        companies: getCompanies(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavCompanies)

// import { createAction } from 'redux-actions';
import { push } from 'connected-react-router'
import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { Actions, ListActions } from 'Constants'
import moment from 'moment';

// export const getSearch = state => state.licenses.list.search

export const search = (companyId, appId, startDate = moment().subtract(30, 'days'), LastEvaluatedKey) => {
    return async (dispatch, getState) => {
        // dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.SEARCH, search })

        console.log('stats', companyId, appId, startDate.format('YYYYMMDD'), LastEvaluatedKey);

        try {
            let req = {
                headers: {},
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    ...(appId && {appId}),
                    startDate: startDate.format('YYYYMMDD'),
                    ...(LastEvaluatedKey && {ExclusiveStartKey : LastEvaluatedKey})
                }
            }

            console.log('req', req);

            API.post("activations", "/statistics/find", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Might need to check if date range has changed
                // Check search params haven't changed
                // const currentSearch = getSearch(getState())
                // if (currentSearch !== search) return

                dispatch({
                    type: Actions.UI_STATISTICS_LIST,
                    listAction: ListActions.ITEMS,
                    items,
                    LastEvaluatedKey,
                    ...(appId && {appId})
                })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

// export const licensesPage = (companyId, appId) => {
//     return async (dispatch, getState) => {
//         const state = getState()
//         const searchValue = state.licenses.list.search
//         const LastEvaluatedKey = state.licenses.list.LastEvaluatedKey
//
//         dispatch(search(companyId, appId, searchValue, LastEvaluatedKey))
//     }
// }

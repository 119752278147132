import React, { Component, useState } from 'react'
import { Route, NavLink, Switch } from "react-router-dom";
import { Button, Dropdown,Popup, Grid, Icon, Input, Menu } from 'semantic-ui-react'

import { connect } from 'react-redux';
import styled from 'styled-components'

import Amplify from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import { signInConfig, signUpConfig } from './AuthenticationConfigs'
import aws_config from './config'

import { mqttPost, performIOTConnect } from './actions/iot'
import { loadUserAccessData, loadCompanyData } from './actions'

import ErrorMessage from './ErrorMessage'

import AppHeaderContent from './AppHeaderContent'

// Navigation
import NavCompanies from './Views/Nav/NavCompanies'
import NavCompany from './Views/Nav/NavCompany'
import NavApplication from './Views/Nav/NavApplication'

// Company
import Applications from './Views/Company/Applications'
import ApplicationNew from './Views/Company/ApplicationNew'
import Licenses from './Views/Company/Licenses'
import CompanyBilling from './Views/Company/CompanyBilling'
import CompanyTeam from './Views/Company/CompanyTeam'
import CompanySettings from './Views/Company/CompanySettings'

// Company Settings
import CompanySettingsDetails from './Views/Company/Settings/CompanySettingsDetails'
import CompanySettingsFastSpring from './Views/Company/Settings/CompanySettingsFastSpring'

// App
import ApplicationDashboard from './Views/Company/App/ApplicationDashboard'
import ApplicationFeedback from './Views/Company/App/ApplicationFeedback'
import ApplicationIssues from './Views/Company/App/ApplicationIssues'
import ApplicationReleases from './Views/Company/App/ApplicationReleases'
import ApplicationReleasesNew from './Views/Company/App/ApplicationReleasesNew'

// App Settings
import ApplicationSettings from './Views/Company/App/Settings/ApplicationSettings'
import ApplicationLicenseTypes from './Views/Company/App/Settings/LicenseTypes'
import ApplicationStoreURL from './Views/Company/App/Settings/ApplicationStoreURL'
import ApplicationPaymentProcessors from './Views/Company/App/Settings/PaymentProcessors'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "sidenav header"
        "sidenav main";
    height: 100vh;
    background: #eceff1;

    @media (prefers-color-scheme: dark) {
        color: #ddd !important;
        background-color: #222 !important;
    }
`

const AppHeader = styled.header`
    grid-area: header;

    background-color: rgba(236, 239, 241) !important;

    @media (prefers-color-scheme: dark) {
        color: #ddd !important;
        background-color: #272727 !important;
    }
`

const AppSidenav = styled.aside`
    grid-area: sidenav;
    // background-color: rgba(221, 228, 230);

    background-color: rgb(236, 239, 241);
    border-right: 1px solid #dee0e2;

    @media (prefers-color-scheme: dark) {
        color: #ddd !important;
        background-color: #272727 !important;
    }
`

const AppMain = styled.main`
    grid-area: main;
    overflow: hidden;
    // padding: 20px;
    background-color: rgba(236, 239, 241);

    @media (prefers-color-scheme: dark) {
        color: #ddd !important;
        background-color: #222 !important;

        background-color: #1b1b1b !important;
    }
`

// When component mounts, ie when company changes or page is reloaded, refresh the company data
class CompanyDataLoader extends Component {
    async componentDidMount() {
        const { match: { params: { companyId } } } = this.props
        this.props.loadCompanyData(companyId)
    }

    render() {
        return null
    }
}
const ConnectedCompanyDataLoader = connect(
    state => ({}),
    dispatch => ({
        loadCompanyData: (...params) => dispatch(loadCompanyData(...params))
    })
)(CompanyDataLoader)


// Main app component
class App extends Component {

    async componentDidMount() {
        this.props.loadUserAccessData();
    }

    render() {
        const { performIOTConnect } = this.props

        const languageOptions = [
          { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
          { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
          { key: 'Danish', text: 'Danish', value: 'Danish' },
        ]

        return (
            <GridContainer>

                { /* Load company specific data */ }
                <Route path="/:companyId/*" component={ConnectedCompanyDataLoader}/>

                <AppHeader>
                    <Switch>
                        <Route path="/:companyId/applications/:appId/:mode?/:subMode?" component={AppHeaderContent}/>
                        <Route path="/:companyId/:mode?/:subMode?" component={AppHeaderContent}/>
                        <Route path="/" exact component={AppHeaderContent}/>
                    </Switch>
                    { /* <Button basic onClick={ () => performIOTConnect() }>Connect</Button> */ }
                </AppHeader>

                <AppSidenav>
                    <Switch>
                        <Route path="/:companyId/applications/:appId/:mode?/:subMode?" component={NavApplication}/>
                        <Route path="/:companyId/:mode?/:subMode?" component={NavCompany}/>
                        <Route path="/" exact component={NavCompanies}/>
                    </Switch>
                </AppSidenav>

                <AppMain>
                    <Route path="/:companyId/newApplication" exact component={ApplicationNew}/>
                    <Route path="/:companyId/applications" exact component={Applications}/>
                    <Route path="/:companyId/licenses" exact component={Licenses}/>
                    <Route path="/:companyId/team" exact component={CompanyTeam}/>
                    <Route path="/:companyId/billing" exact component={CompanyBilling}/>
                    <Route path="/:companyId/settings" exact component={CompanySettings}/>
                    <Route path="/:companyId/settings/company" exact component={CompanySettingsDetails}/>
                    <Route path="/:companyId/settings/fastspring" exact component={CompanySettingsFastSpring}/>

                    <Route path="/:companyId/applications/:appId" exact component={ApplicationDashboard}/>
                    <Route path="/:companyId/applications/:appId/Feedback" exact component={ApplicationFeedback}/>
                    <Route path="/:companyId/applications/:appId/Issues" exact component={ApplicationIssues}/>
                    <Route path="/:companyId/applications/:appId/Releases" exact component={ApplicationReleases}/>
                    <Route path="/:companyId/applications/:appId/Releases/New" exact component={ApplicationReleasesNew}/>
                    <Route path="/:companyId/applications/:appId/settings/appDetails" exact component={ApplicationSettings}/>
                    <Route path="/:companyId/applications/:appId/settings/storeURL" exact component={ApplicationStoreURL}/>
                    <Route path="/:companyId/applications/:appId/settings/licenseTypes" exact component={ApplicationLicenseTypes}/>

                    <Route path="/:companyId/applications/:appId/settings/paymentProcessors" exact component={ApplicationPaymentProcessors}/>
                </AppMain>

                <ErrorMessage/>

            </GridContainer>
        )
    }
}

export default withAuthenticator(connect(
    state => ({

    }),
    dispatch => ({
        loadUserAccessData: () => dispatch(loadUserAccessData()),
        performIOTConnect: () => dispatch(performIOTConnect())
    })
)(App), {
    includeGreetings: false,
    signInConfig,
    signUpConfig
});

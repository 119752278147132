import { Actions } from 'Constants'
import { defaultListState, processList } from './InfiniteListReducer'

const defaultState = {
    customerLicenses: {},
    showCustomerEditor: false,
    list: defaultListState,
    selectedId: undefined
}

const Licenses = (state = defaultState, action) => {

    switch (action.type) {
        case Actions.UI_LICENSES_LIST:
            return {
                ...state,
                list: processList(state.list, action, (license) => { return license.activationCode })
            }

        case Actions.UI_CHANGE_COMPANY:
            return defaultState

        case Actions.UI_LICENSES_SELECT:
            return {
                ...state,
                showCustomerEditor: false,
                selectedId: action.id
            }

        case Actions.UI_LICENSES_CUSTOMER_ITEMS: {
            return {
                ...state,
                customerLicenses: action.items.reduce((result, item) => {
                    const { kind, activationCode } = item
                    const license = result[activationCode] || {}

                    if (kind == "activation") {
                        const activations = license.activations || []
                        return { ...result, [activationCode]: { ...license, activations:[ ...activations, item ] } };
                    }

                    return { ...result, [item.activationCode]: { ...license, ...item } }
                }, { ...state.items })
            }
        }

        case Actions.UI_LICENSES_TOGGLE_CUSTOMER_EDITOR: {
            return {
                ...state,
                showCustomerEditor: !state.showCustomerEditor,
                editingLicense: action.license
            }
        }

        default:
        return state
    }
}

export default Licenses

import crypto from 'crypto'
import React from 'react'
// import moment from 'moment'
import { Button, Divider } from 'semantic-ui-react'
import styled from 'styled-components'

import { connect } from 'react-redux';
// import { toggleCustomerEditor } from './actions'
import { getSelectedIssue } from 'selectors/issues'

// import LicenseCard from './LicenseCard'

const EditorContainer = styled.div`
    overflow-y: scroll;
    padding: 20px !important;
`

const Message = styled.h2`
    font-size: 1.3em;
`

const CallStack = styled.pre`
    font-family: courier;
    background: white;
    border: 1px solid #d6d6d6;
    padding: 1em;
    line-height: 1.4em;
`
const Line = styled.div`
    padding: .4em 1em;
    :nth-child(even) {
        background: #f3f3f3;
    }
`

class IssueViewer extends React.Component {
    render() {
        const { issue } = this.props

        if (!issue) {
            return null
        }
        const { issueMeta } = issue
        const { callStack, message, machineInfo, type } = issueMeta

        return (
            <EditorContainer>
                <Message>{message}</Message>
                <CallStack>
                {
                    callStack.map((line) => {
                        return (
                            <React.Fragment>
                                <Line>{line}</Line>
                            </React.Fragment>
                        )
                    })
                }
                </CallStack>
            </EditorContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        issue: getSelectedIssue(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    // toggleCustomerEditor: (...params) => dispatch(toggleCustomerEditor(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueViewer)

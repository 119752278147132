import { createSelector } from 'reselect'

import { getSelectedAppId } from './index'

// PAYMENT PROCESSORS

export const getPaymentProcessors = state => state.paymentProcessors.paymentProcessorsById

// export const getPaymentProcessorsForApp = createSelector(
//     [getSelectedAppId, getPaymentProcessors],
//     (appId, paymentProcessors) => {
//         return Object.keys(licenseTypes).reduce((result, licenseTypeId) => {
//             const licenseType = licenseTypes[licenseTypeId]
//             if (licenseType.appId !== appId) return result
//             return {
//                 ...result,
//                 [licenseTypeId]: licenseType
//             }
//         }, {})
//     }
// )

export const getPaymentProcessorsLoading = state => state.paymentProcessors.isLoading
export const getPaymentProcessorsMoreDataAvailable = state => state.paymentProcessors.list.moreDataAvailable
export const getSelectedPaymentProcessorId = (state) => state.paymentProcessors.selectedId
export const getSelectedPaymentProcessor = createSelector(
    [getSelectedPaymentProcessorId, getPaymentProcessors],
    (paymentProcessorId, paymentProcessors) => {
        return paymentProcessors[paymentProcessorId]
    }
)

//
export const showEditor = (state) => state.paymentProcessors.showEditor
export const fsLinkToken = (state) => state.paymentProcessors.fsLinkToken
export const loadingFSLink = (state) => state.paymentProcessors.loadingFSLink

// export const getCustomerLicenses = (state) => state.licenses.customerLicenses

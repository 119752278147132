import React from 'react'
import styled from 'styled-components'
import { Icon, Item } from 'semantic-ui-react'

import { ImageSrc } from 'DevAntMethods'
import Spinner from '../../Spinner'

const SpinnerContainer = styled.div`
    position: relative;
    height: 100%;
`

export const Loading = () => (
    <SpinnerContainer>
        <Spinner />
    </SpinnerContainer>
)

export const CompanyImage = ({ icon: {imageId, imageExt}, size=32 }) => (
    <AppIcon src={ImageSrc(imageId, imageExt, size*2)} size={size} />
)

const AppIcon = styled.img`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    flex: 1fr;
    margin: 0 1em 0 .5em;
`

export const StyledMiniItem = styled(Item)`
    color: ${props => props.selected ? '#198378 !important' : '#446f7b !important'};
    background: ${props => props.selected ? "rgba(185, 215, 215) !important" : ""};
    padding: .3em !important;

    /* Wan't set in NavCompany */
    margin-bottom: 1em;


    border-bottom: 1px solid #8080808f;
    border-radius: 0 !important;
    padding: 1.2em !important;
    font-weight: 400;
    font-size: 1.1em !important;


    @media (prefers-color-scheme: dark) {
        background-color: ${props => props.selected ? "#3637388c !important" : ""};
        color: #82adb9 !important;
    }
`

export const StyledItem = styled(Item)`
    color: ${props => props.selected ? '#198378 !important' : '#446f7b !important'};
    background: ${props => props.selected ? "rgba(185, 215, 215) !important" : ""};
    border-radius: 0 !important;
    padding: 1.2em !important;
    font-weight: 400;
    font-size: 1.1em !important;

    @media (prefers-color-scheme: dark) {
        background-color: ${props => props.selected ? "#3637388c !important" : ""};
        color: #82adb9 !important;
    }
`

export const StyledIcon = styled(Icon)`
    /* color: #198378 !important; */
    margin-right: .8em !important;

    color: #f6fcff;
    filter: drop-shadow(0px 1px 1px #868686);
    font-size: 1.3em !important;

    @media (prefers-color-scheme: dark) {
        background-color: ${props => props.selected ? "#3637388c !important" : ""};
        color: #82adb9 !important;
    }
`


/***********************************************************/
// Nav

const StyledGrid = styled.div`
    display: grid;
    align-items: top;
`

const StyledGridRow = styled.div`
    display: flex;
    align-items: center;
    padding: .8em 0em;
    color: #565656;
    cursor: pointer;

    font-size: 1em;
    font-weight: 400;

    /* Make sure child element content squishes, otherwise it affects layout and throws column alignment out */
    > * {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    /* Column styles */
    icon {
        display: inline-block;
        white-space: nowrap;
    }
    name {
        font-weight: 400;
    }

    /* Add some spacing left/right of the table */
    >:first-child { margin-left:1em; }
    >:last-child { margin-right:1em; }

    /* Header row style */
    &:first-child {
        position: sticky;
        top: 0;
        border-bottom: 1px solid #ececec;
        white-space: nowrap;
        background-color: rgb(236, 239, 241);
    }

    /* Row styles */
    &:not(:first-child) {
        border-bottom: 1px solid #ececec;
    }
    &:hover:not(:first-child) {
        background: rgba(0, 0, 0, 0.03);
        cursor: pointer;
    }

    background: ${props => props.selected ? "rgba(185, 215, 215) !important" : ""};
    background: ${props => props.selected ? "rgb(207, 212, 216) !important" : ""};

`

export class Nav extends React.Component {

    render() {
        const { items, company, app, mode, subMode, onBack, selectItem } = this.props

        const selected = (item, routePrefix) => {
            if (!item.route) return false
            if (!subMode) return item.route === mode
            return routePrefix === mode && item.route === subMode
        }

        const MenuRow = ({key, onClick, image, icon, text, selected}) => (
            <StyledGridRow key={key} onClick={onClick} selected={selected}>
                { icon.imageId && <CompanyImage icon={icon || {}} size={32} /> }
                { !icon.imageId && <StyledIcon name={icon} /> }
                <name>{text}</name>
            </StyledGridRow>
        )
        // { image && <CompanyImage icon={image || {}} size={32} /> }
        // { !image && <StyledIcon name={icon} /> }
        const IconRow = ({item}) => (
            <MenuRow key="back" icon={item.icon} text={item.name} onClick={onBack} />
        )

        // <MenuRow key="back" icon={'chevron left'} text={app.name} onClick={onBack} />

        const MenuRows = ({items, routePrefix}) => items.map((item, i) => (
            <MenuRow key={item.name} icon={item.icon} text={item.name} selected={selected(item, routePrefix)} onClick={() => selectItem(item, routePrefix)} />
        ))

        const MenuItems = items.map((item, i) => (
            <React.Fragment key={item.name} >
                <MenuRows items={[item]}/>

                { item.children &&
                    <MenuRows items={item.children} routePrefix={item.route}/>
                }
            </React.Fragment>
        ))

        const currentItem = app || company

        return (
            <StyledGrid >
                { currentItem &&
                    <IconRow item={currentItem} />
                }

                { MenuItems }
            </StyledGrid>
        )
    }
}

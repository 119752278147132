import React from 'react'
import moment from 'moment'
import { Item } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { search, getSearch, loadMore, select } from 'actions/paymentProcessors'

import { getLicenseTypes } from 'selectors/licenseTypes'
import { getPaymentProcessors, getSelectedPaymentProcessorId, getPaymentProcessorsMoreDataAvailable } from 'selectors/paymentProcessors'

import styled from 'styled-components'

import { InfiniteItemsList } from 'InfiniteItemsList'

const paymentProcessorName = ({ name }) => ( name && <strong>{name}</strong>)
// const customerName = ({ firstName, lastName }) => (<React.Fragment>{firstName} {lastName}</React.Fragment>)
const createdDate = ({ createdAt }) => {
    const s = moment(createdAt).format("MMM Do, YYYY")
    return s
}

const StyledItemDescription = styled(Item.Description)`
    margin: .4em;
`

const StyledItemMeta = styled(Item.Meta)`
    margin: 0 .4em;
`

const itemRenderer = (paymentProcessor, props) => {
    console.log('itemRenderer', props);
    const { licenseTypes } = props

    const { licenseTypeId } = paymentProcessor
    const licenseType = licenseTypes[licenseTypeId] || {}
    

    return (
        <React.Fragment>
            <StyledItemMeta style={{float:'right'}}>
                {createdDate(paymentProcessor)}
            </StyledItemMeta>

            <StyledItemMeta>
                Record ID: {paymentProcessor.paymentProcessorId}
            </StyledItemMeta>

            <StyledItemMeta>
                Processor: {paymentProcessorName(paymentProcessor)}
            </StyledItemMeta>

            <StyledItemMeta>
                License Type: {licenseType.name}
            </StyledItemMeta>
        </React.Fragment>
    )
}

const getItemId = (item) => item.paymentProcessorId

const InfinitePaymentProcessorsList = (props) => (<InfiniteItemsList divided selection style={{margin:0, overflowY: 'auto'}} {...props} />)

const mapStateToProps = (state, props) => ({
    licenseTypes: getLicenseTypes(state, props),
    items: getPaymentProcessors(state, props),
    selectedItemId: getSelectedPaymentProcessorId(state, props),
    moreDataAvailable: getPaymentProcessorsMoreDataAvailable(state, props),
    getItemId,
    itemRenderer
})

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, search) => dispatch(search(companyId, appId, search)),
    loadMore: (companyId, appId) => dispatch(loadMore(companyId, appId)),
    select: (item) => dispatch(select(item))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfinitePaymentProcessorsList)

import React from 'react'
import styled from 'styled-components'
import { FileDropzone } from 'FileDropzone'

const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    max-width: 600px;
`
const Label = styled.div`
    // border: 1px solid red;
    text-align: right;
    font-weight: bold;
    margin-right: 2em !important;
    line-height: 3em;
`
const Column = styled.div`
    grid-column-start: ${ props => props.start ? props.start : 0 };
    grid-column-end: ${ props => props.end ? props.end+1 : 0 };
`
const RowGap = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    height: ${ props => props.height || '2em' };
`
const Input = styled.input`
    // margin: 1em 0;
    grid-area: radio;
`
const Status = styled.div`
    display: inline-block;
    padding: .2em 1em;

    border-radius: .3em;
    font-size: .8em;
    font-weight: 500;

    ${ props => props.live && `
        background-color: #cae9b0;
        color: #386f3b;
    `}

    ${ props => props.testing && `
        background-color: #f1f698;
        color: #552;
    `}

    ${ props => props.draft && `
        background-color: #dfe0e2;
        color: #5a5f64;
    `}
`
const Info = styled.div`
    grid-area: info;
    color: gray;
    margin-top: .4em;
`
const Text = styled.input`
    margin: 0em;
    max-width: 100%;
    flex: 1 0 auto;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.2em;
    padding: 0.67em 1em;
    background: white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.3rem;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
`

export const Form = ({label, description, ...rest}) => (
    <Grid>
        { /* Row */ }
        <Label>Status</Label>
        <Column><Input type="radio" /></Column>
        <Column><Status live>Live</Status></Column>

        { /* Row */ }
        <Label></Label>
        <Label></Label>
        <Column><Info>This version will become live. Update will become available to all users and this version's binaries will be available by the default download links.</Info></Column>

        { /* Gap */ }
        <RowGap height="1em" />

        { /* Row */ }
        <Label></Label>
        <Column><Input type="radio" /></Column>
        <Column><Status testing>Testing</Status></Column>

        { /* Row */ }
        <Label></Label>
        <Label></Label>
        <Column><Info>This version will become live. Update will become available to all users and this version's binaries will be available by the default download links.</Info></Column>

        { /* Gap */ }
        <RowGap height="1em" />

        { /* Row */ }
        <Label></Label>
        <Column><Input type="radio" /></Column>
        <Column><Status draft>Draft</Status></Column>

        { /* Row */ }
        <Label></Label>
        <Label></Label>
        <Column><Info>This version will become live. Update will become available to all users and this version's binaries will be available by the default download links.</Info></Column>

        { /* Gap */ }
        <RowGap />

        { /* Row */ }
        <Label>Build Number</Label>
        <Column start={2} end={3}><Text placeholder='Application Name'/></Column>

        { /* Row */  }
        <Label></Label>
        <Column start={2} end={3}><Info>Enter the same version number as in CFBundleVersion key. Use a, b, rc for alpha, beta or release candidates.</Info></Column>


        { /* Gap */ }
        <RowGap />

        { /* SHORT BUNDLE VERSION */ }
        <Label>Display Version</Label>
        <Column start={2} end={3}><Text placeholder='Short Bundle Version'/></Column>

        { /*  */  }
        <Label></Label>
        <Column start={2} end={3}><Info>This version will be visible to the users and should match the CFBundleShortVersionString key.</Info></Column>


        { /* Gap */ }
        <RowGap />

        { /* VERSION CODENAME */ }
        <Label>Version Codename</Label>
        <Column start={2} end={3}><Text placeholder='Version Codename'/></Column>

        { /*  */  }
        <Label></Label>
        <Column start={2} end={3}><Info>Enter a codename for this version. Will be visible in DevAnt only.</Info></Column>



        { /* Release Files */ }
        <Label>Release Files</Label>
        <Column start={2} end={3}></Column>

        <Label></Label>
        <Column start={2} end={3}><FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={null} buttonText="Upload .ZIP" description=".ZIP file will be used to generate delta updates."/></Column>

        <Label></Label>
        <Column start={2} end={3}><FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={null} buttonText="Upload .DMG" description=".DMG file is recommended for the initial download."/></Column>

        <Label></Label>
        <Column start={2} end={3}><FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={null} buttonText="Upload .dSYM.ZIP" description=".dSYM.zip is necessary for crash report symbolication."/></Column>


        { /* Release Notes */ }
        <Label>Release Notes</Label>
        <Column start={2} end={3}>
            <Info>
                Add Release Notes for each localization, using Markdown for text formatting. Learn more about Markdown syntax.
                Note that {'{{product}}, {{version}}, and {{date}}'} variables may be used to fetch the product name, current short bundle version number and release date.
            </Info>
        </Column>




    </Grid>
)

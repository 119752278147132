import React from 'react'
import { Header } from 'semantic-ui-react'

import styled from 'styled-components'
import Spinner from './Spinner'

const Padding = styled.div`
    padding: 20px;
    height: 100%;
`

const StyledContainer = styled.div`
    box-shadow: 0 1px 3px 0 #cfd8dc;
    height: 100%;
    background: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${props => props.rows};
    grid-template-areas: ${props => props.areas};

    // needed for Spinnner
    position: relative !important;

    // hugContent
    height: ${props => props.hugContent ? "auto" : "100%"};
    width: 100%;
    max-width: ${props => props.hugContent ? "800px" : "auto"};
    margin: 0 auto;

    @media (prefers-color-scheme: dark) {
        box-shadow: 0 1px 6px 2px #00000040;
        // border: 1px solid #3e3e3e;
        background-color: #222 !important;
    }
`

const StyledHeader = styled(Header)`
    margin: 0 !important;
    grid-area: header;
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #ececec !important;
    margin-left: 0px !important;
    margin-right: 0px !important;

    @media (prefers-color-scheme: dark) {
        background-color: #222 !important;
        color: #82adb9 !important;


        border-bottom: 1px solid #3e3e3e !important;

    }
`
const StyledContentArea = styled.div`
    grid-area: content;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    overflow: scroll;
`
const StyledFooter = styled.div`
    grid-area: footer;
    border: 0 !important;
    border-radius: 0 !important;
    border-top: 1px solid #ececec !important;
`

const EmptyContent = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    grid-area: content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ababab;

    font-size: 1.3em;@media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
    }
`

class ContentContainer extends React.Component {
    render() {
        const { padding, header, children, buttons, hugContent=false, loading=false, emptyContent=false, emptyMessage="Nothing to see here" } = this.props

        const rowHeader = header ? '60px' : ''
        const rowContent = 'auto'
        const rowFooter = buttons ? '80px' : ''

        const rows = `${rowHeader} ${rowContent} ${rowFooter}`

        const areaHeader = header ? '"header"' : ''
        const areaContent = '"content"'
        const areaFooter = buttons ? '"footer"' : ''

        const areas = `${areaHeader} ${areaContent} ${areaFooter}`

        const nothingToSee = (
            <EmptyContent>
                <p>{ emptyMessage }</p>
            </EmptyContent>
        )

        const content = emptyContent ? nothingToSee : children

        const container = (
            <StyledContainer hugContent={hugContent} rows={rows} areas={areas}>
                { loading &&
                    <Spinner />
                }

                { header &&
                    <StyledHeader  attached='top' >
                        { header }
                    </StyledHeader>
                }

                <StyledContentArea>
                    { !loading &&
                        content
                    }
                </StyledContentArea>

                { buttons &&
                    <StyledFooter>
                        {buttons}
                    </StyledFooter>
                }
            </StyledContainer>
        )

        if (!padding) {
            return container
        }

        return (
            <Padding>
                { container }
            </Padding>
        )
    }
}

export default ContentContainer

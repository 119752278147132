import { Actions } from 'Constants'

import { defaultListState, processList } from './InfiniteListReducer'

const defaultState = {
    list: defaultListState,
    selectedId: undefined
}

const releasesId = (releases) => { return releases.releasesId }

const Releases = (state = defaultState, action) => {
    switch (action.type) {

        case Actions.UI_RELEASES_LIST:
            return {
                ...state,
                list: processList(state.list, action, releasesId)
            }

        case Actions.UI_CHANGE_COMPANY:
            return defaultState

        case Actions.UI_CHANGE_APP:
            return defaultState

        case Actions.UI_RELEASES_SELECT:
            return {
                ...state,
                selectedId: action.release.releaseId
            }

        default:
        return state
    }
}

export default Releases

import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';
import { Button, Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import { connect } from 'react-redux';
import { appUpdate, getApplicationLicenseData } from 'actions/app'
import { performAppNav } from 'actions/nav'
import { getSelectedApp, getSelectedCompany } from 'selectors'
import { appLicenseData, loadingAppLicenseData } from 'selectors/app'

import ContentContainer from 'ContentContainer'
import { ImageSrc } from 'DevAntMethods'

import { FileDropzone } from 'FileDropzone'

const StyledContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 2em;
`

const StyledForm = styled(Form)`
    width: 100%;
    max-width: 600px !important;
`

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 2em;
`

const StyledButtons = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px !important;
`
const ButtonSpacer = styled.div`
    flex: 1;
`

const iconUploadDescription = 'Upload your application icon here. The image should be in .png or .jpg format. (.png with transparent background is the best option).'

class ApplicationSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            app: null
        }
    }

    componentDidMount() {
        const { app } = this.props
        this.setState({
            app
        })
    }

    updateApp(event, name) {
        const { app } = this.state
        this.setState({
            app: {
                ...app,
                [name] : event.target.value
            }
        })
    }

    onCancel() {
        const { performAppNav, match: { params: { companyId, appId } } } = this.props
        performAppNav({ companyId, appId, route:'settings' })
    }

    onSave() {
        const { appUpdate } = this.props
        const { app, file } = this.state

        appUpdate(app, file)
    }

    onFile(file) {
        this.setState({
            file
        })
    }

    onGetAppLicenseData() {
        const { getApplicationLicenseData, company, app } = this.props

        const { companyId } = company
        const { appId, bundleId } = app

        getApplicationLicenseData(companyId, appId, bundleId)
    }

    render() {
        const { app } = this.state
        const { appLicenseData, loadingAppLicenseData } = this.props

        if (!app) return null

        const { name="", bundleId="", icon={} } = app
        const { imageId, imageExt } = icon

        const imageSrc = ImageSrc(imageId, imageExt, 128)


        const buttons = (
            <StyledButtonContainer>
                <StyledButtons>
                    <Button size='small' type='submit' basic onClick={ () => this.onCancel() }>Cancel</Button>
                    <ButtonSpacer />
                    <Button size='small' type='submit' color="green" onClick={ () => this.onSave() }>Save Changes</Button>
                </StyledButtons>
            </StyledButtonContainer>
        )

        const GetAppLicenseDataButton = (<Button onClick={() => this.onGetAppLicenseData()}>Get App License Data</Button>)

        return (
            <ContentContainer buttons={buttons} hugContent>

                <StyledContentContainer>
                    <StyledForm>
                        <Form.Field>
                            <label>Application Name</label>
                            <Input placeholder='Application Name' value={name} onChange={ (e) => this.updateApp(e, 'name') } />
                        </Form.Field>

                        <Form.Field>
                            <label>Bundle ID</label>
                            <Input placeholder='Bundle ID' value={bundleId} onChange={ (e) => this.updateApp(e, 'bundleId') } />
                        </Form.Field>

                        <Form.Field>
                            <label>App Icon</label>
                            <FileDropzone onFile={ (file) => this.onFile(file) } currentImageSrc={imageSrc} description={iconUploadDescription}/>
                        </Form.Field>

                        <ContentContainer emptyContent={appLicenseData === undefined} emptyMessage={GetAppLicenseDataButton} loading={loadingAppLicenseData}>
                        {appLicenseData}
                        </ContentContainer>


                    </StyledForm>
                </StyledContentContainer>

            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        company: getSelectedCompany(state, props),
        app: getSelectedApp(state, props),
        appLicenseData: appLicenseData(state, props),
        loadingAppLicenseData: loadingAppLicenseData(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params)),
    appUpdate: (...params) => dispatch(appUpdate(...params)),
    getApplicationLicenseData: (...params) => dispatch(getApplicationLicenseData(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationSettings)

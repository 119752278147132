import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Icon } from 'semantic-ui-react'
import { StyledButton } from 'StyledButton'
import styled from 'styled-components'

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Description = styled.div`
    margin: 2em 0;
    display: block;
    color: #a5a5a5;
`

const Thumb = styled.div`
  display: inline-flex;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  :focus { outline: none; }
`

const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
  :focus { outline: none; }
`

const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
`
const Right = styled.div`
  flex: 1;
`
const defaultButtonText = 'Choose Image'
const defaultDescription = 'Upload your logo here. The image should be in .png or .jpg format (.png with transparent background is the best option).'

export const FileDropzone = (props) => {

    const {
        onFile,
        currentImageSrc,
        description = defaultDescription,
        buttonText = defaultButtonText,
        accept = 'image/*'
    } = props

    const [files, setFiles] = useState([])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => onFile(file));

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
    })

    const thumbs = files.map(file => (
        <Thumb key={file.name}>
            <ThumbInner>
                <Img src={file.preview} />
            </ThumbInner>
        </Thumb>
    ))

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files])

    const file = files[0] || {}

    const previewSrc = file.preview ? file.preview : currentImageSrc


    return (
        <ThumbsContainer>
            <Thumb { ...getRootProps({className: 'dropzone'}) }>
                <input { ...getInputProps() } />
                <ThumbInner>
                    <Img src={previewSrc} />
                </ThumbInner>
            </Thumb>

            <Right>
                <div { ...getRootProps({className: 'dropzone'}) }>
                    <StyledButton  mini outline><Icon name="upload" />{buttonText}</StyledButton>
                </div>

                <Description>
                    { description }
                </Description>
            </Right>
        </ThumbsContainer>
    )
}

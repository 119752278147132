import React from 'react'

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
import { getSelectedApp } from 'selectors'

import styled from 'styled-components'

import { Loading, Nav } from './Nav'

const applicationMenuItems = [{
    name: 'Dashboard',
    icon: 'dashboard',
    route: undefined
}, {
    name: 'Release Management',
    icon: 'bus',
    route: 'releases'
}, {
    name: 'Issues',
    icon: 'bug',
    route: 'issues'
}, {
    name: 'Feedback',
    icon: 'bullhorn',
    route: 'feedback'
}, {
    name: 'Settings',
    icon: 'cog',
    route: 'settings',
    subRoute: 'appDetails',

    children: [{
        name: 'App Details',
        icon: '',
        route: 'appDetails'
    }, {
        name: 'Store URL',
        icon: '',
        route: 'storeURL'
    }, {
        name: 'License Types',
        icon: '',
        route: 'licenseTypes'
    }, {
        name: 'License Generators',
        icon: '',
        route: 'paymentProcessors'
    }]
}]

class NavApplication extends React.Component {

    selectItem(item, routePrefix) {
        const { performAppNav, match: { params: { companyId, appId } } } = this.props

        if (item.subRoute) {
            const route = `${item.route}/${item.subRoute}`
            performAppNav({companyId, appId, route })
        }
        else {
            const route = routePrefix ? `${routePrefix}/${item.route}` : item.route
            performAppNav({companyId, appId, route })
        }
    }

    showCompany() {
        const { performAppNav, match: { params: { companyId } } } = this.props
        performAppNav({companyId})
    }

    render() {
        const { performAppNav, company, app, match: { params: { mode, subMode } } } = this.props

        if (!app) return (<Loading />)

        return (
            <Nav items={applicationMenuItems} company={company} app={app} mode={mode} subMode={subMode} onBack={() => this.showCompany()} selectItem={(item, routePrefix) => this.selectItem(item, routePrefix)} />
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        app: getSelectedApp(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavApplication)

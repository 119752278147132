export const Actions = {

    // Obtain access token used in
    AUTH_TOKEN_LOAD                         : 'AUTH_TOKEN_LOAD',

    // Application errors
    APP_ERROR                               : 'APP_ERROR',

    // Actions for loading User record, companies, apps
    USER                                    : 'USER',
    COMPANIES                               : 'COMPANIES',
    APPS                                    : 'APPS',

    // Actions for loading specific company data
    // COMPANY_DATA                            : 'COMPANY_DATA',
    LICENSE_TYPE_DATA                       : 'LICENSE_TYPE_DATA',
    PAYMENT_PROCESSOR_DATA                  : 'PAYMENT_PROCESSOR_DATA',


    UI_CHANGE_COMPANY                       : 'UI_CHANGE_COMPANY',
    UI_CHANGE_APP                           : 'UI_CHANGE_APP',
    UI_CHANGE_MODE                          : 'UI_CHANGE_MODE',

    UI_RELEASES_LIST                        : 'UI_RELEASES_LIST',

    UI_FEEDBACK_LIST                        : 'UI_FEEDBACK_LIST',

    UI_APPLICATION_LICENSE_DATA             : 'UI_APPLICATION_LICENSE_DATA',

    UI_STATISTICS_LIST                      : 'UI_STATISTICS_LIST',

    UI_ISSUES_LIST                          : 'UI_ISSUES_LIST',
    UI_ISSUES_SELECT                        : 'UI_ISSUES_SELECT',
    UI_ISSUES_SHOW_EDITOR                   : 'UI_ISSUES_SHOW_EDITOR',

    UI_LICENSE_TYPES_LIST                   : 'UI_LICENSE_TYPES_LIST',
    UI_LICENSE_TYPES_SELECT                 : 'UI_LICENSE_TYPES_SELECT',
    UI_LICENSES_TYPES_TOGGLE_EDITOR         : 'UI_LICENSES_TYPES_TOGGLE_EDITOR',

    UI_PAYMENT_PROCESSORS_LIST              : 'UI_PAYMENT_PROCESSORS_LIST',
    UI_PAYMENT_PROCESSORS_SELECT            : 'UI_PAYMENT_PROCESSORS_SELECT',
    UI_PAYMENT_PROCESSORS_TOGGLE_EDITOR     : 'UI_PAYMENT_PROCESSORS_TOGGLE_EDITOR',
    UI_PAYMENT_PROCESSORS_FS_LINK           : 'UI_PAYMENT_PROCESSORS_FS_LINK',

    UI_LICENSES_LIST                        : 'UI_LICENSES_LIST',
    UI_LICENSES_SELECT                      : 'UI_LICENSES_SELECT',
    UI_LICENSES_TOGGLE_CUSTOMER_EDITOR      : 'UI_LICENSES_TOGGLE_CUSTOMER_EDITOR',

    UI_LICENSES_CUSTOMER_ITEMS              : 'UI_LICENSES_CUSTOMER_ITEMS'


}

export const ListActions = {
    SEARCH  : 'SEARCH',
    ITEMS   : 'ITEMS',
    SELECT  : 'SELECT',
    UPDATE  : 'UPDATE'
}

import { Actions } from 'Constants'

import { defaultListState, processList } from './InfiniteListReducer'

const defaultState = {
    list: defaultListState,
    selectedId: undefined
}

const teamId = (team) => { return team.teamId }

const Team = (state = defaultState, action) => {
    switch (action.type) {

        case Actions.UI_TEAM_LIST:
            return {
                ...state,
                list: processList(state.list, action, teamId)
            }

        case Actions.UI_CHANGE_COMPANY:
            return defaultState

        case Actions.UI_CHANGE_APP:
            return defaultState

        case Actions.UI_TEAM_SELECT:
            return {
                ...state,
                selectedId: action.team.teamId
            }

        default:
        return state
    }
}

export default Team

import React from 'react'
// import { Button, Header, Input} from 'semantic-ui-react'
import { connect } from 'react-redux';
import { search } from 'actions/paymentProcessors'
import { search as licenseTypesSearch } from 'actions/licenseTypes'

import { getSelectedCompany, getSelectedApp } from 'selectors'
import { getPaymentProcessors, getPaymentProcessorsLoading, getSelectedPaymentProcessorId } from 'selectors/paymentProcessors'
import { getLicenseTypes } from 'selectors/licenseTypes'


import ContentContainer from 'ContentContainer'

import styled from 'styled-components'
// import Spinner from './Spinner'

import PaymentProcessorsList from './PaymentProcessorsList'
import PaymentProcessorsEditor from './PaymentProcessorsEditor'

const ContentFlex = styled.div`
    display: flex;
    height: 100%;
`
const PaymentProcessorsContentLeft = styled.div`
    flex: 1;
    overflow: scroll;
    border-right: 1px solid #d8d8d8 !important;

    @media (prefers-color-scheme: dark) {
        border-right: 1px solid #3e3e3e !important;
    }
`
const PaymentProcessorsContentRight = styled.div`
    flex: 1;
    overflow: scroll;
    background: rgb(251, 251, 251);

    @media (prefers-color-scheme: dark) {
        color: #82adb9 !important;
        background-color: #222 !important;
    }

`



// Create separate records for license types


// APPLICATION

/*
    create app
    create license type
        name
        disabled
        maxActivations
        validForDays
        validUntil
        maxAppVersion
        betaOnly
        customTag
        products

    create payment processor
        name
        type (fastspring, paddle)
*/


// LICENSE GEN FROM FS

/*
    request { userInfo, token }
    decode token { licenseTypeId, appId, companyId }
    read licenseType record
    generate code
    write to activations table with userInfo, licenseTypeId, appId, companyId
    return code
*/



// ACTIVATE LICENSE CODE

/*
    Validate code
        read license from activations
        read license type from applicaitons
    Validate license type
        check license type not deleted
        check max activations

    insert/replace activation in license.activations array
*/

// CHECK ACTIVATION

/*
    decode license file

    everything from activate license code

*/


class PaymentProcessors extends React.Component {

    async componentDidMount() {
        const { paymentProcessors, licenseTypes, search, licenseTypesSearch, match: { params: { appId, companyId } } } = this.props

        if (Object.keys(licenseTypes).length === 0) {
            licenseTypesSearch(companyId, appId)
        }

        if (Object.keys(paymentProcessors).length === 0) {
            search(companyId, appId)
        }
    }

    render() {
        const { isLoading, paymentProcessors, selectedPaymentProcessorId } = this.props

            // const header = (
            //     <React.Fragment>
            //         <Button size='mini' basic floated='right' icon='add' content="New Customer" onClick={() => { this.newCustomer() }}/>
            //         <Input size='mini' icon='search' placeholder='Search...' value={search} onChange={(event) => this.handleSearch(event)} />
            //     </React.Fragment>
            // )

            //header={header}

//<LicenseEditor {...this.props} issueId={selectedIssueId} />
        return (
            <ContentContainer padding loading={isLoading} emptyContent={Object.keys(paymentProcessors).length === 0}>
                <ContentFlex>
                    <PaymentProcessorsContentLeft>
                        <PaymentProcessorsList {...this.props} />
                    </PaymentProcessorsContentLeft>

                    <PaymentProcessorsContentRight>
                        <PaymentProcessorsEditor {...this.props} id={selectedPaymentProcessorId} />
                    </PaymentProcessorsContentRight>
                </ContentFlex>
            </ContentContainer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        licenseTypes: getLicenseTypes(state, props),
        company: getSelectedCompany(state, props),
        app: getSelectedApp(state, props),
        selectedPaymentProcessorId: getSelectedPaymentProcessorId(state),
        paymentProcessors: getPaymentProcessors(state, props),
        isLoading: getPaymentProcessorsLoading(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    search: (companyId, appId, searchValue) => dispatch(search(companyId, appId, searchValue)),
    licenseTypesSearch: (companyId, appId, searchValue) => dispatch(licenseTypesSearch(companyId, appId, searchValue))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentProcessors)

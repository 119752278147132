// import { createAction } from 'redux-actions';
import { push } from 'connected-react-router'
import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { Actions, ListActions } from 'Constants'

export const getSearch = state => state.licenses.list.search

export const toggleCustomerEditor = (license) => ({
    type: Actions.UI_LICENSES_TOGGLE_CUSTOMER_EDITOR,
    license
})

export const licenseToggleBlock = (license) => {
    return async (dispatch, getState) => {

        const newLicense = {
            ...license,
            blocked: !license.blocked
        }

        const { PK, SK, blocked } = newLicense

        let req = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: {
                PK,
                SK,
                blocked
            }
        }

        try {
            API.post("activations", "/activation/updateActivation", req).then(response => {
                // const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data
                dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.UPDATE, license:newLicense })
            }).catch(error => {
                console.log(error)
            })
        } catch (e) {
            // alert(e);
        }

    }
}

export const licenseUpdate = (license) => {
    return async (dispatch, getState) => {

        const { companyId, sortKey, companyName, firstName, lastName, email } = license

        let req = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: {
                companyId,
                sortKey,
                companyName,
                firstName,
                lastName,
                email
            }
        }

        try {
            API.post("activations", "/activation/updateActivation", req).then(response => {
                // const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.UPDATE, license:license })
                dispatch({ type: Actions.UI_LICENSES_TOGGLE_CUSTOMER_EDITOR })
            }).catch(error => {

                console.log('something bad', error)
            })
        } catch (e) {
            // alert(e);
        }
    }
}

export const licenseResetActivations = (license) => {
    return async (dispatch, getState) => {

        const newLicense = {
            ...license,
            activations: []
        }

        dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.UPDATE, license:newLicense })
    }
}

const isEmailAddress = (email) => {
    //https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

export const search = (companyId, appId, search = "", LastEvaluatedKey) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.SEARCH, search })

        console.log('search', companyId, appId, search, LastEvaluatedKey);

        try {

            const email = isEmailAddress(search) ? { email: search } : {}
            const activationCode = search.startsWith('id') ? { activationCode: search } : {}
            const subscriptionId = search.endsWith('S') ? { subscriptionId: search } : {}
            const orderId = search === '' ? {} : { orderId: search }


            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    ...email,
                    ...activationCode,
                    ...orderId,
                    ...subscriptionId,
                    ...(LastEvaluatedKey && {ExclusiveStartKey : LastEvaluatedKey})
                }
            }

            console.log('req', req);

            API.post("activations", "/activation/find", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Check search params haven't changed
                const currentSearch = getSearch(getState())
                if (currentSearch !== search) return

                dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.ITEMS, items, LastEvaluatedKey })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

export const licensesPage = (companyId, appId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const searchValue = state.licenses.list.search
        const LastEvaluatedKey = state.licenses.list.LastEvaluatedKey

        dispatch(search(companyId, appId, searchValue, LastEvaluatedKey))
    }
}

export const licensesSelect = (license) => {
    return async (dispatch, getState) => {
        const { activationCode, companyId, email } = license

        // dispatch({ type: Actions.UI_LICENSES_SEARCH, search })
        dispatch({ type: Actions.UI_LICENSES_CUSTOMER_ITEMS, items:[] })
        dispatch({ type: Actions.UI_LICENSES_SELECT, id: activationCode })

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    email
                }
            }

            API.post("activations", "/activation/find", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Check search params haven't changed
                // const currentSearch = getLicensesSearch(getState())
                // if (currentSearch !== search) return

                dispatch({ type: Actions.UI_LICENSES_CUSTOMER_ITEMS, items, LastEvaluatedKey })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// import UI from './UI'

import User from './User'
import Companies from './Companies'
import Apps from './Apps'
import AppStats from './AppStats'
import Licenses from './Licenses'
import Team from './Team'

import Releases from './Releases'
import Feedback from './Feedback'
import Issues from './Issues'
import LicenseTypes from './LicenseTypes'
import PaymentProcessors from './PaymentProcessors'

import AppError from './AppError'

export default (history) => combineReducers({
    router: connectRouter(history),

    // Application errors
    appError: AppError,

    // Logged in user
    user: User,

    // Companies
    companies: Companies,

    // Selected Company
    apps : Apps,
    appStats : AppStats,
    licenses: Licenses,
    team: Team,

    // Selected App
    releases: Releases,
    feedback: Feedback,
    issues: Issues,
    licenseTypes: LicenseTypes,
    paymentProcessors: PaymentProcessors
})

import React from 'react'
import moment from 'moment'
import { Button, Card, Icon } from 'semantic-ui-react'

import { connect } from 'react-redux';
import { licenseToggleBlock, licenseResetActivations } from 'actions/licenses'
import { getCustomerLicense, getLicenseBlocked, getLicenseTypeForLicense, getAppForLicense } from 'selectors/licenses'

import { ImageSrc } from 'DevAntMethods'

import styled from 'styled-components'
import Spinner from 'Spinner'

const FormattedDate = ({ date }) => { return moment(date, "YYYYMMDDHHmmss").format("MMM Do, YYYY") }

const Tag = styled.div`
  border: 1px solid red;
  border-radius: 0.3em;
  padding: 0.5em 0.8em;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1em;
  vertical-align: baseline;
  margin-right: .3em;

  /* Restyle */
  color: white;
  font-weight: 800;
  border-radius: 3em;
  text-transform: capitalize;
`;

const BlockedTag = styled(Tag)`
  border-color: #DB2828;
  background: #DB2828;
  /* color: #b51414; */
`
const SubscriptionTag = styled(Tag)`
  border-color: #21BA45;
  background: #21BA45;
  /* color: #0ba52f; */
`
const ExpiredTag = styled(Tag)`
  border-color: #FBBD08;
  background: #FBBD08;
  /* color: #d8a100; */
`

const AppContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const AppText = styled.h1`
  color: #4a4a4a;
  margin: 0 0 0 0.3em;
  font-size: 1.4em;
  font-weight: 400;
  flex: 1;
`;

const StyledAppIcon = styled.img`
    display: block;
    margin: 0 0.3em;
    width: 32px;
    height: 32px;
`

const AppIcon = ({icon}) => {
    if (!icon) return null
    const {imageId, imageExt} = icon

    return (
        <StyledAppIcon src={ImageSrc(imageId, imageExt, 128)} />
    )
}

const AppHeader = ({ app:{icon, name}, blocked, subscription, expired }) => (
  <AppContainer>
    <AppIcon icon={icon} />
    <AppText>{name}</AppText>
    { expired && <ExpiredTag>expired</ExpiredTag> }
    { subscription && <SubscriptionTag>subscription</SubscriptionTag> }
    { blocked && <BlockedTag>blocked</BlockedTag> }
  </AppContainer>
)

const LicenseHeader = (props) => (
    <AppHeader {...props} />
)

const StyledCard = styled(Card)`
    width: 100% !important;
    border-radius: .35em !important;
    /* box-shadow: 0px 1px 3px 0px #bbbbbb, 0px 0px 0px 1px #e2e2e2 !important; */
    box-shadow: 0px 1px 3px 0px #D4D4D5 !important;
    /* box-shadow: none !important; */

    @media (prefers-color-scheme: dark) {
        background: #333 !important;
        box-shadow: 0px 1px 3px 0px #111, 0px 0px 0px 1px #222 !important;
    }
`
const StyledValue = styled.span`
    display: block;
    color: #444;
    margin-bottom: 1em;
    font-size: .9em;
    font-weight: 500;

    @media (prefers-color-scheme: dark) {
        color: #aaa;
    }
`
const ContentContainer = styled(Card.Content)`
    display: flex;
    border-top: .5px solid #efefef !important;
`
const ContentColumnSpacer = styled.div`
    width: 1em;
`
const ContentColumn = styled.div`
    flex: 1;
`

const Activation = styled.div`
    display: flex;
`

class LicenseCard extends React.Component {

    blockLicense(license) {
        const { licenseToggleBlock } = this.props
        licenseToggleBlock(license)
    }

    resetActivations(license) {
        const { licenseResetActivations } = this.props
        licenseResetActivations(license)
    }

    render() {
        const { license, blocked, app = {}, licenseType = {} } = this.props
        const { name:licenseTypeName, maxActivations } = licenseType

        console.log('license', license)
        console.log('app', app);

        if (!license) {
            return null
        }

        const { licenseTypeId, activationCode, orderId = "—", subscriptionId = "—", expiryDate, orderDate, activations = [] } = license

        const blockLicenseText = blocked ? 'Unblock' : 'Block'

        const activated = activations.reduce((result, item) => {
            if (item.status === 'activated') {
                return [...result, item]
            }
            return result
        }, [])
        const deactivated = activations.reduce((result, item) => {
            if (item.status === 'deactivated') {
                return [...result, item]
            }
            return result
        }, [])


        return (
            <StyledCard>
                <Card.Header>
                    <LicenseHeader {...license} bundle={licenseTypeId} app={app} />
                </Card.Header>

                <ContentContainer>
                    <ContentColumn>
                        <Card.Meta>License Type</Card.Meta>
                        <StyledValue>{ licenseTypeName }</StyledValue>
                    </ContentColumn>
                    <ContentColumnSpacer></ContentColumnSpacer>
                    <ContentColumn>
                        <Card.Meta>Activation Code</Card.Meta>
                        <StyledValue>{ activationCode }</StyledValue>
                        <Card.Meta>Activation Usage</Card.Meta>
                        <StyledValue>{activated.length} of {maxActivations}</StyledValue>
                    </ContentColumn>
                </ContentContainer>

                <ContentContainer>
                    <ContentColumn>
                        <Card.Meta>Order Reference</Card.Meta>
                        <StyledValue>{ orderId }</StyledValue>
                        {
                            orderDate &&
                            <React.Fragment>
                                <Card.Meta>Order Date</Card.Meta>
                                <StyledValue><FormattedDate date={orderDate} /></StyledValue>
                            </React.Fragment>
                        }
                    </ContentColumn>
                    <ContentColumnSpacer></ContentColumnSpacer>
                    <ContentColumn>
                        <Card.Meta>Subscription ID</Card.Meta>
                        <StyledValue>{ subscriptionId }</StyledValue>
                        {
                            expiryDate &&
                            <React.Fragment>
                                <Card.Meta>Expiration Date</Card.Meta>
                                <StyledValue><FormattedDate date={expiryDate} /></StyledValue>
                            </React.Fragment>
                        }
                    </ContentColumn>
                </ContentContainer>


                <ContentContainer>
                    <ContentColumn>
                        <Card.Meta>Activations</Card.Meta>
                        {
                            activated.map( (activation) => (
                                <Activation key={activation.identifier}>
                                    { activation.activatedAt }
                                    <br />
                                    { activation.machineId }
                                    <br />
                                    { activation.machineInfo.machineModel }
                                    <br />
                                    { activation.machineInfo.machineSerialNumber }
                                    <br />
                                    { activation.machineInfo.operatingSystemVersionString }
                                    <br />
                                    { activation.machineInfo.fullUserName }
                                    <br />
                                    <br />
                                </Activation>
                            ))
                        }
<hr/>
                        {
                            deactivated.map( (activation) => (
                                <Activation key={activation.identifier}>
                                    { activation.activatedAt }
                                    <br />
                                    { activation.machineId }
                                    <br />
                                    { activation.machineInfo.machineModel }
                                    <br />
                                    { activation.machineInfo.machineSerialNumber }
                                    <br />
                                    { activation.machineInfo.operatingSystemVersionString }
                                    <br />
                                    { activation.machineInfo.fullUserName }
                                    <br />
                                    <br />
                                </Activation>
                            ))
                        }
                    </ContentColumn>
                </ContentContainer>


                <Card.Content extra>
                  <Button basic size='mini' onClick={() => { this.blockLicense(license) }}><Icon name='ban' />{blockLicenseText}</Button>
                  <Button basic size='mini' onClick={() => { this.resetActivations(license) }}><Icon name='redo' />Reset Activations</Button>
                </Card.Content>
            </StyledCard>
        )
    }
}


// aLicense() {
//     GSI1_SK: "laurence@pixelcortex.com:20191002100140"
//     activations: []
//     apps: ["com.71squared.glyphdesigner2"]
//     companyId: "3cda0edb-3dba-4edb-86bf-b184afa55821"
//     companyName: "Pixel Cortex Ltd"
//     createdAt: 1572386118782
//     email: "laurence@pixelcortex.com"
//     expiryDate: "20201002010000"
//     firstName: "Laurence"
//     lastName: "McDonald"
//     activationCode: "id415271473163odr"
//     orderDate: "20191002100140"
//     orderId: "71S191002-8799-67115"
//     sortKey: "20191002100140|id415271473163odr"
//     subscription: true
//     updatedAt: 1572386118782
// }

const mapStateToProps = (state, props) => {
    return {
        license: getCustomerLicense(state, props),
        blocked: getLicenseBlocked(state, props),
        licenseType: getLicenseTypeForLicense(state, props),
        app: getAppForLicense(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    licenseToggleBlock: (license) => dispatch(licenseToggleBlock(license)),
    licenseResetActivations: (license) => dispatch(licenseResetActivations(license))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseCard)

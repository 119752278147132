import crypto from 'crypto'
import React from 'react'
// import moment from 'moment'
import { Button, Divider, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { connect } from 'react-redux';
import { toggleCustomerEditor } from 'actions/licenses'
import { getLicenses, getCustomerLicenses, showCustomerEditor } from 'selectors/licenses'

import LicenseCard from './LicenseCard'
import LicenseEditorCustomer from './LicenseEditorCustomer'

const EditorContainer = styled.div`
    overflow-y: scroll;
    padding: 20px !important;
`

const StyledIcon = styled(Icon)`
    color: gray;
`

// const FormattedDate = ({ date }) => { return moment(date, "YYYYMMDDHHmmss").format("MMM Do, YYYY") }
const EditButton = (props) => <StyledIcon name="edit outline" {...props}></StyledIcon> // <Button basic icon='edit outline' {...props} />


const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
`
const Avatar = styled.img`
  border-radius: 50%;
  width: 50px;
  margin: .5em 1em .5em 0;
`
const CustomerName = styled.h1`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  flex: 1;
`
const CustomerEmail = styled.div`
  color: gray;
  font-size: .8em;
`
const CustomerHeader = ({license, editCustomerInfo}) => {
    const { companyName, firstName, lastName, email } = license
    const title = companyName || `${firstName||''} ${lastName||''}`
    const desc = companyName ? `${firstName||''} ${lastName||''}` : undefined

    const hash = crypto.createHash('md5').update(email).digest("hex")
    const gravatar = `https://www.gravatar.com/avatar/${hash}`

    return (
      <CustomerContainer>
        <Avatar src={gravatar} />
        <CustomerName>{title}
          <CustomerEmail>{desc}</CustomerEmail>
          <CustomerEmail><a href={`mailto:${email}`}>{email}</a></CustomerEmail>
        </CustomerName>
        <EditButton onClick={() => { editCustomerInfo(license) }}/>
      </CustomerContainer>
    )
}

const formattedLicenseCount = (count) => `${count} License${ count > 1 ? 's' : '' }`

class LicenseEditor extends React.Component {
    render() {
        const { license, customerLicenses, showEditor, toggleCustomerEditor, licenseUpdate } = this.props

        if (!license) {
            return null
        }

        return (
            <EditorContainer>
                { !showEditor &&
                    <CustomerHeader license={license} editCustomerInfo={ () => toggleCustomerEditor(license) } />
                }

                { showEditor &&
                    <LicenseEditorCustomer license={license} onCancel={ () => toggleCustomerEditor() } onSave={ licenseUpdate } />
                }

                <Divider></Divider>
                <div>
                    <Button basic size="mini" icon="plus" content="Add License" floated="right" />
                    <h3>{formattedLicenseCount(Object.keys(customerLicenses).length)}</h3>
                </div>

                {
                    Object.values(customerLicenses).map( (license) => (
                        <LicenseCard key={license.activationCode} activationCode={license.activationCode} />
                    ))
                }
            </EditorContainer>
        )
    }
}

// aLicense() {
//     GSI1_SK: "laurence@pixelcortex.com:20191002100140"
//     activations: []
//     apps: ["com.71squared.glyphdesigner2"]
//     companyId: "3cda0edb-3dba-4edb-86bf-b184afa55821"
//     companyName: "Pixel Cortex Ltd"
//     createdAt: 1572386118782
//     email: "laurence@pixelcortex.com"
//     expiryDate: "20201002010000"
//     firstName: "Laurence"
//     lastName: "McDonald"
//     activationCode: "id415271473163odr"
//     orderDate: "20191002100140"
//     orderId: "71S191002-8799-67115"
//     sortKey: "20191002100140|id415271473163odr"
//     subscription: true
//     updatedAt: 1572386118782
// }

const mapStateToProps = (state, props) => {
    return {
        license: getLicenses(state, props)[props.activationCode],
        customerLicenses: getCustomerLicenses(state, props),
        showEditor: showCustomerEditor(state, props)
    }
}

const mapDispatchToProps = dispatch => ({
    toggleCustomerEditor: (...params) => dispatch(toggleCustomerEditor(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseEditor)

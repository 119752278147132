import { Actions } from 'Constants'

const defaultState = {
    isLoading: true,
    user: {}
}

const Data = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.USER:
            const { isLoading = false, user = {} } = action.payload

            return {
                ...state,
                isLoading,
                user
            }

        default:
        return state
    }
}

export default Data

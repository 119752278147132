import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { Actions } from 'Constants'
import { performAppNav } from './nav'

export const appUpdate = (app, file) => {
    return async (dispatch, getState) => {

        const uploadImage = async (file) => {
            if (!file) return null
            return await Storage.vault.put(file.name, file)
        }

        const saveApp = async (uploadResult) => {
            let req = {
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    ...app,
                    ...(uploadResult && { s3ImageKey: uploadResult.key })
                }
            }

            return await API.put("activations", `/application`, req)
        }

        const updateUI = (result) => {
            const { Attributes: { meta, ...rest } } = result
            dispatch({ type: Actions.APPS, payload: { apps: [{ ...meta, ...rest }] } })

            const { companyId, appId } = app

            performAppNav({ companyId, appId, route:'settings' })(dispatch, getState)
        }

        uploadImage(file)
            .then(saveApp)
            .then(updateUI)
            .catch(error => {
                const { response: { data } } = error
                dispatch({ type: Actions.APP_ERROR, error })
            })
    }
}


export const getApplicationLicenseData = (companyId, appId, bundleId) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_APPLICATION_LICENSE_DATA, loading: true, appLicenseData:undefined })

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    appId,
                    bundleId
                }
            }

            API.post("activations", `/application/appLicenseData`, req).then(response => {
                const { success, appLicenseData } = response.data
                dispatch({ type: Actions.UI_APPLICATION_LICENSE_DATA, loading:false, appLicenseData })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

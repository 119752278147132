import { API } from "aws-amplify";
import { Actions, ListActions } from 'Constants'
import uuidV4 from 'uuid/v4'

export const getSearch = state => state.licenseTypes.list.search

export const toggleEditor = (licenseType) => ({
    type: Actions.UI_LICENSES_TYPES_TOGGLE_EDITOR,
    licenseType
})

export const createNewLicenseType = (companyId, appId) => {
    return async (dispatch, getState) => {

        const licenseTypeId = uuidV4()
        const createdAt = Date()
        const updatedAt = Date()

        const licenseType = {
            licenseTypeId,
            identifier: licenseTypeId,
            key: `${appId}|settings|licenseType|${createdAt}|licenseTypeId`,
            kind: 'licenseType',
            name: 'New type',
            maxActivations: 3,
            companyId,
            appId,
            createdAt,
            updatedAt
        }

        dispatch({ type: Actions.LICENSE_TYPE_DATA, payload: { licenseTypes: [licenseType] } })
        dispatch({ type: Actions.UI_LICENSE_TYPES_SELECT, licenseType })
        dispatch({
           type: Actions.UI_LICENSES_TYPES_TOGGLE_EDITOR,
           licenseType,
           createNewRecord: true
       })
    }
}

export const search = (companyId, appId, search, LastEvaluatedKey) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_LICENSE_TYPES_LIST, listAction: ListActions.SEARCH, search })

        if (!appId) { return }

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    appId,
                    type: 'settings|licenseType',
                    search,
                    ...(LastEvaluatedKey && {ExclusiveStartKey : LastEvaluatedKey})
                }
            }

            API.post("activations", "/application/find", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Check search params haven't changed
                const currentSearch = getSearch(getState())
                // if (currentSearch !== search) return


                // array of licenseTypes, merge meta into main object
                const licenseTypes = items.reduce((result, item) => {
                    const { meta, ...rest } = item
                    return [ ...result, { ...rest, ...meta } ]
                }, [])

                dispatch({ type: Actions.UI_LICENSE_TYPES_LIST, listAction: ListActions.ITEMS, items: licenseTypes, LastEvaluatedKey })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

export const loadMore = (companyId, appId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const search = state.licenseTypes.list.search
        const LastEvaluatedKey = state.licenseTypes.list.LastEvaluatedKey

        dispatch(search(companyId, appId, search, LastEvaluatedKey))
    }
}

export const select = (licenseType) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_LICENSE_TYPES_SELECT, licenseType })
    }
}

export const licenseTypeUpdate = (license) => {
    return async (dispatch, getState) => {

        const { companyId, sortKey, companyName, firstName, lastName, email } = license

        let req = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: {
                companyId,
                sortKey,
                companyName,
                firstName,
                lastName,
                email
            }
        }

        try {
            // API.post("activations", "/activation/updateActivation", req).then(response => {
            //     // const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data
            //
            //     dispatch({ type: Actions.UI_LICENSES_LIST, listAction: ListActions.UPDATE, license:license })
            //     dispatch({ type: Actions.UI_LICENSES_TOGGLE_CUSTOMER_EDITOR })
            // }).catch(error => {
            //
            //     console.log('something bad', error)
            // })
        } catch (e) {
            // alert(e);
        }
    }
}

import styled from 'styled-components'

const primaryColors = {
    default: '#ececec',
    green: '#73c757',
    turquoise: '#4cbfef',
    red: 'red',
}

const secondaryColors = {
    default: '#a0a0a0',
    default: '#dadada',
    green: '#50b92c',
    turquoise: '#3eb8ea',
    red: '#e40000',
}

const defaultTextColor = '#686868'

const colorName = (props) => {
    if (props.green) return 'green'
    if (props.turquoise) return 'turquoise'
    if (props.red) return 'red'
    return 'default'
}

const fontSize = props => props.mini ? '.8em' : '1em'
const backgroundColor = props => props.outline ? null : primaryColors[colorName(props)]
const hoverBackgroundColor = props => secondaryColors[colorName(props)]
const borderColor = props => props.outline ? secondaryColors[colorName(props)] : primaryColors[colorName(props)]

const textColor = props => {
    if (colorName(props) === 'default') {
        return props.outline ? '#777777' : defaultTextColor
    }

    return props.outline ? secondaryColors[colorName(props)] : '#fff'
}

export const StyledButton = styled.button`
    cursor: pointer;
    outline: none;
    border-width: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    padding: 0.7em 1em;
    border-radius: .4em;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;

    border-width: 1px;
    border-style: solid;

    background: ${ backgroundColor };
    border-color: ${ borderColor };
    color: ${ textColor };
    font-size: ${ fontSize };

    :hover {
        background: ${ hoverBackgroundColor };
        border-color: ${ hoverBackgroundColor };
        color: white;
    }
`

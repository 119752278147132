// import * as ApiGateway from '../lib/api-gateway';
// import * as Cognito from '../lib/aws-cognito';
// import * as IoT from '../lib/aws-iot';
// import {
//     DEVICE_CONNECTED_STATUS_CHANGED,
//     IDENTITY_UPDATED,
//     MESSAGE_HANDLER_ATTACHED,
// } from './types';

// import { Actions } from '../Constants'


// import { handleSignOut } from './authActions';
// import { newMessage } from './messageActions';

/**
 * Ask API Gateway for Iot policy whitelisting for public rooms
 * 1. Ensure we are logged in
 * 2. Fetch AWS credentials from sessionStorage, identityId and attach to a new MQTT client
 * 3. Ask for Connect, Publish, Subscribe, Receive policies
 */
// export const acquirePublicPolicies = (connectCallback, closeCallback) => (
//   async (dispatch, getState) => {
//     const {
//       connectPolicy,
//       publicPublishPolicy,
//       publicSubscribePolicy,
//       publicReceivePolicy,
//     } = getState().iot;
//
//     const loggedIn = await Cognito.authUser();
//     if (!loggedIn) {
//       handleSignOut()(dispatch);
//       return Promise.resolve();
//     }
//     const identityId = Cognito.getIdentityId();
//     dispatch({ type: IDENTITY_UPDATED, identityId });
//     const awsCredentials = JSON.parse(sessionStorage.getItem('awsCredentials'));
//
//     IoT.initNewClient(awsCredentials);
//     IoT.attachConnectHandler(connectCallback);
//     IoT.attachCloseHandler(closeCallback);
//
//     if (!connectPolicy) {
//       ApiGateway.attachConnectPolicy().then(() =>
//         dispatch({ type: CONNECT_POLICY_ATTACHED }));
//     }
//
//     if (!publicPublishPolicy) {
//       ApiGateway.attachPublicPublishPolicy().then(() =>
//         dispatch({ type: PUBLIC_PUBLISH_POLICY_ATTACHED }));
//     }
//
//     if (!publicSubscribePolicy) {
//       ApiGateway.attachPublicSubscribePolicy().then(() =>
//         dispatch({ type: PUBLIC_SUBSCRIBE_POLICY_ATTACHED }));
//     }
//
//     if (!publicReceivePolicy) {
//       ApiGateway.attachPublicReceivePolicy().then(() =>
//         dispatch({ type: PUBLIC_RECEIVE_POLICY_ATTACHED }));
//     }
//
//     return Promise.resolve();
//   }
// );

// /**
//  * Change device connected status
//  *
//  * @param {bool} status - whether the device is connected or not
//  */
// export const deviceConnectedStatusChanged = status => ({
//   type: DEVICE_CONNECTED_STATUS_CHANGED,
//   deviceConnected: status,
// });
//
// /**
//  * Handler for incoming MQTT messages
//  * Uses currying to bind Redux dispatch and getState and returns handler
//  *
//  * @params {function} dispatch - Redux dispatch
//  * @params {function} getState - Redux getState
//  * @returns {function}  The wrapped onNewMessage handler
//  *
//  * onNewMessage handler parses message and forwards to newMessage action
//  * @params {string} topic - The topic that the message was published to
//  * @params {string} jsonPayload - JSON payload of the message
//  */
// const onNewMessageWithRedux = (dispatch, getState) => (
//   (topic, jsonPayload) => {
//     const payload = JSON.parse(jsonPayload.toString());
//     const { message } = payload;
//     newMessage(message, topic)(dispatch, getState);
//   }
// );
//
// export const attachMessageHandler = () => (
//   (dispatch, getState) => {
//     const attached = getState().iot.messageHandlerAttached;
//     if (!attached) {
//       IoT.attachMessageHandler(onNewMessageWithRedux(dispatch, getState));
//     }
//     dispatch({ type: MESSAGE_HANDLER_ATTACHED, attached: true });
//     return Promise.resolve();
//   }
// );




import Amplify, { API, PubSub } from 'aws-amplify';

export const mqttPost = (companyName="Unknown Company") => {
    // PubSub.publish('room/public/tom/us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf13', { msg: 'Hello to all subscribers!' })
     // PubSub.publish('room/public/tom/us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18', { msg: 'Hello to all subscribers!' })
     PubSub.publish('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18', { msg: 'Hello to all subscribers!', value:1, companyName })
}

const onNewMessageWithRedux = (dispatch, getState) => (
 (provider) => {

     const { value } = provider
     const { type, payload } = value

     dispatch({type, payload})

     console.log('Message received', value)
 }
);

export const performIOTConnect = () => {
    return async (dispatch, getState) => {

        let req = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            body: {}
        }

        API.post("activations", "/iot/policy", req).then(response => {

            PubSub.subscribe('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18').subscribe({
                next: onNewMessageWithRedux(dispatch, getState),
                error: error => console.error(error),
                close: () => console.log('Done'),
            });
        }).catch(error => {
            console.log(error)
        })
    }
}




export const mqttConnect = () => {

    Amplify.Auth.currentCredentials().then((info) => {
        const cognitoIdentityId = info.data.IdentityId;
        console.log(cognitoIdentityId);
    });

    let req = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {}
    }

    API.post("activations", "/iot/policy", req).then(response => {

        console.log(response.data);


        // PubSub.subscribe('topic').subscribe({
        //     next: data => console.log('Message received', data),
        //     error: error => console.error(error),
        //     close: () => console.log('Done'),
        // });


        PubSub.subscribe('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18').subscribe({
            next: provider => {
                const { value } = provider
                const { companyName } = value



                console.log('Message received', value)
            },
            error: error => console.error(error),
            close: () => console.log('Done'),
        });

        // PubSub.publish('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18', { msg: 'Hello to all subscribers!' })


        // PubSub.subscribe('room/public/tom/#').subscribe({
        //     next: data => console.log('Message received', data),
        //     error: error => console.error(error),
        //     close: () => console.log('Done'),
        // });

    }).catch(error => {
        console.log(error)
    })

     //
     //        PubSub.subscribe('myTopic').subscribe({
     //            next: data => console.log('Message received', data),
     //            error: error => console.error(error),
     //            close: () => console.log('Done'),
     //        });
     //
     //
     // PubSub.publish('myTopic', { msg: 'Hello to all subscribers!' })


}

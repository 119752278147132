import { Actions } from 'Constants'

const defaultState = {
    error: undefined
}

const AppError = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.APP_ERROR:
            return {
                ...state,
                error: action.error
            }

        default:
        return state
    }
}

export default AppError

// import { createAction } from 'redux-actions';
import { push } from 'connected-react-router'
import Amplify, { API, PubSub } from "aws-amplify";
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { Storage } from "aws-amplify";
import { Actions, ListActions } from 'Constants'
import { getSelectedCompanyId, getSelectedAppId } from 'selectors'
import { performAppNav } from './nav'

import aws_config from 'config'

Amplify.configure(aws_config)

// Apply plugin with configuration
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint: 'wss://a24yvz9ipi6436-ats.iot.us-east-1.amazonaws.com/mqtt',
}))

export const authToken = () => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.AUTH_TOKEN_LOAD, payload: { isLoading: true }})

        try {
            let req = {
                response: true
            }

            API.get("activations", "/activations/resourceAccess", req).then(response => {
                console.log(response);
                dispatch({ type: Actions.AUTH_TOKEN_LOAD, payload:{ isLoading: false } })
            }).catch(error => {
                console.log(error)
            });
        } catch (e) {
            // alert(e);
        }
    }
}

export const loadUserAccessData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.USER, payload:{ isLoading: true } })
        dispatch({ type: Actions.COMPANIES, payload:{ isLoading: true } })
        dispatch({ type: Actions.APPS, payload:{ isLoading: true } })

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL
                }
            }

            API.get("activations", "/application/find", req).then(response => {
                const { records } = response.data
                const { Count, Items:items, ScannedCount } = records

                // user info record, there is only 1 per user
                const user = items.reduce((result, item) => {
                    if (item.kind !== "user") return result

                    const { userMeta, ...rest } = item
                    return { ...rest, ...userMeta}
                }, {})

                dispatch({ type: Actions.USER, payload:{ isLoading: false, user } })


                // array of companies, merge companyMeta into main object
                const companies = items.reduce((result, item) => {
                    if (item.kind !== "company") return result

                    const { meta, ...rest } = item
                    const company = { ...rest, ...meta }

                    return [ ...result, company ]
                }, [])

                dispatch({ type: Actions.COMPANIES, payload:{ isLoading: false, companies } })


                // array of apps, merge appMeta into main object
                const apps = items.reduce((result, item) => {
                    if (item.kind !== "app") return result

                    const { meta, ...rest } = item
                    const app = { ...rest, ...meta }

                    return [ ...result, app ]
                }, [])

                dispatch({ type: Actions.APPS, payload:{ isLoading: false, apps } })


            }).catch(error => {
                console.log(error)
            })

            // const resourceAccess = await this.resourceAccess();
            // console.log(resourceAccess);
            // this.setState({ resourceAccess });
        } catch (e) {
            // alert(e);
        }
    }
}

export const loadCompanyData = (companyId) => {
    return async (dispatch, getState) => {
        // dispatch({ type: Actions.COMPANY_DATA, payload:{ isLoading: true } })
        dispatch({ type: Actions.LICENSE_TYPE_DATA, payload:{ isLoading: true } })
        dispatch({ type: Actions.PAYMENT_PROCESSOR_DATA, payload:{ isLoading: true } })

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                // queryStringParameters: {  // OPTIONAL
                // },
                body: {
                    companyId
                }
            }

            API.post("activations", "/application/find", req).then(response => {
                const { records } = response.data
                const { Count, Items:items, ScannedCount } = records

                // array of license types, merge meta into main object
                const licenseTypes = items.reduce((result, item) => {
                    if (item.kind !== "licenseType") return result

                    const { meta, ...rest } = item
                    const licenseType = { ...rest, ...meta }

                    return [ ...result, licenseType ]
                }, [])

                dispatch({ type: Actions.LICENSE_TYPE_DATA, payload:{ isLoading: false, licenseTypes } })

                // array of payment processors, merge meta into main object
                const paymentProcessors = items.reduce((result, item) => {
                    if (item.kind !== "paymentProcessor") return result

                    const { meta, ...rest } = item
                    const paymentProcessor = { ...rest, ...meta }

                    return [ ...result, paymentProcessor ]
                }, [])

                dispatch({ type: Actions.PAYMENT_PROCESSOR_DATA, payload:{ isLoading: false, paymentProcessors } })

                // dispatch({ type: Actions.COMPANY_DATA, payload:{ isLoading: false } })

            }).catch(error => {
                console.log(error)
            })

            // const resourceAccess = await this.resourceAccess();
            // console.log(resourceAccess);
            // this.setState({ resourceAccess });
        } catch (e) {
            // alert(e);
        }
    }
}

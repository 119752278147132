import { createSelector } from 'reselect'

// ISSUES
export const getIssues = state => state.issues.list.items
export const getIssuesLoading = state => state.issues.list.isLoading
export const getIssuesMoreDataAvailable = state => state.issues.list.moreDataAvailable
export const getSelectedIssueId = (state) => state.issues.selectedId
export const getSelectedIssue = createSelector(
    [getSelectedIssueId, getIssues],
    (issueId, issues) => {
        return issues[issueId]
    }
)

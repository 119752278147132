import React from 'react'
import styled from 'styled-components'
import { ImageSrc } from 'DevAntMethods'

const Container = styled.div`
    display: flex;
    align-items: center;
`

const StyledAppIcon = styled.img`
    width: 64px;
    height: 64px;
    flex: 1fr;
    margin: 0 1.5em 0 .5em;
`

const AppName = styled.div`
    flex: 1fr;
    font-weight: 300;
    font-size: ${ props => props.small ? '1.6em' : '2em'};
    color: #446f7b;
`

const AppIcon = ({app}) => {
    const { icon } = app
    if (!icon) return null
    const {imageId, imageExt} = icon
    return (
        <StyledAppIcon src={ImageSrc(imageId, imageExt, 128)} />
    )
}

class AppInfoHeader extends React.Component {
    render() {
        const { app, small } = this.props

        if (!app) return null

        return (
            <Container>
                <AppIcon app={app} />
                <AppName small={small}>{app.name}</AppName>
            </Container>
        )
    }
}

export default AppInfoHeader

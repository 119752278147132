import crypto from 'crypto'
import React from 'react'
// import moment from 'moment'
import { Divider, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { StyledButton } from 'StyledButton'
import { StyledCheckbox } from 'StyledCheckbox'

import { connect } from 'react-redux';
import { toggleEditor } from 'actions/licenseTypes'
import { getLicenseTypes } from 'selectors/licenseTypes'

const EditorContainer = styled.div`
    overflow-y: scroll;
    padding: 20px !important;

`

// const FormattedDate = ({ date }) => { return moment(date, "YYYYMMDDHHmmss").format("MMM Do, YYYY") }
const EditButton = (props) => <StyledButton {...props}><Icon name="edit outline" />Edit</StyledButton>
const EnableButton = (props) => <StyledButton {...props}><Icon name="check circle outline" />Enable</StyledButton>
const DisableButton = (props) => <StyledButton {...props}><Icon name="ban" />Disable</StyledButton>
const GenerateKeyButton = (props) => <StyledButton {...props}><Icon name="refresh" />Generate Test Key</StyledButton>

const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
`
const Avatar = styled.img`
  border-radius: 50%;
  width: 50px;
  margin: .5em 1em .5em 0;
`
const CustomerName = styled.h1`
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  flex: 1;
`
const CustomerEmail = styled.div`
  color: gray;
  font-size: .8em;
`
const LicenseTypeHeader = ({licenseType, toggleEditor}) => {
    // const { companyName, firstName, lastName, email } = license
    // const title = companyName || `${firstName||''} ${lastName||''}`
    // const desc = companyName ? `${firstName||''} ${lastName||''}` : undefined

    const title = licenseType.name
    const desc = licenseType.description

    return (
      <CustomerContainer>
        <CustomerName>{title}
          <CustomerEmail>{desc}</CustomerEmail>
        </CustomerName>
        <EditButton onClick={() => { toggleEditor(licenseType) }}/>
        <EnableButton onClick={() => { toggleEditor(licenseType) }}/>
        <DisableButton onClick={() => { toggleEditor(licenseType) }}/>
      </CustomerContainer>
    )
}

const formattedLicenseCount = (count) => `${count} License${ count > 1 ? 's' : '' }`

class LicenseTypeEditor extends React.Component {
    render() {
        const { licenseType, toggleEditor, licenseTypeUpdate } = this.props

        if (!licenseType) {
            return null
        }

        return (
            <EditorContainer>

                <LicenseTypeHeader licenseType={licenseType} toggleEditor={ () => toggleEditor(licenseType) } />

                <Divider />
                Viewer
                <Divider />

                <GenerateKeyButton/>
                <StyledCheckbox onChange={ this.onChange } data-property-name='requestCustomerData' checked={true} label='Request customer data' control='input' type='checkbox'  />

            </EditorContainer>
        )
        // { showEditor &&
        //     <LicenseEditorCustomer licenseType={licenseType} onCancel={ () => toggleEditor() } onSave={ licenseTypeUpdate } />
        // }
    }
}

const mapStateToProps = (state, props) => {
    return {
        licenseType: getLicenseTypes(state, props)[props.id]
    }
}

const mapDispatchToProps = dispatch => ({
    toggleEditor: (...params) => dispatch(toggleEditor(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseTypeEditor)

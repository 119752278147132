import React from 'react'
import { Message } from 'semantic-ui-react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { clearAppError } from './actions/errors'

const ErrorMessage = ({ error, clear }) => (
    <Message negative>
        <Button floated="right" basic onClick={clear}>Ok</Button>
        <Message.Header>We're sorry, an unexpected error has occurred</Message.Header>
        <p>{error.message}</p>
        <pre>{error.stack}</pre>
    </Message>
)

const ModalErrorMessage = (state) => {
    const { appError: {error}, clearAppError } = state

    if (!error) return null

    return (
        <Modal open={true} centered={false} basic>
            <ErrorMessage error={error} clear={clearAppError}/>
        </Modal>
    )
}

const mapStateToProps = (state, props) => {
    return {
        appError: state.appError
    }
}

const mapDispatchToProps = dispatch => ({
    clearAppError: () => dispatch(clearAppError())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalErrorMessage)

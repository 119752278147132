import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { Actions } from 'Constants'

import { performAppNav } from './nav'

export const companyUpdate = (company, file) => {
    return async (dispatch, getState) => {
        const uploadImage = async (file) => {
            if (!file) return null
            return await Storage.vault.put(file.name, file)
        }

        const saveCompany = async (uploadResult) => {
            let req = {
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    ...company,
                    ...(uploadResult && { s3ImageKey: uploadResult.key })
                }
            }

            return await API.put("activations", `/application`, req)
        }

        const updateUI = (result) => {
            const { Attributes: { meta, ...companyAttributes } } = result
            dispatch({ type: Actions.COMPANIES, payload: { companies: [{ ...companyAttributes, ...meta }] } })

            const { companyId } = company

            performAppNav({ companyId, route:'settings' })(dispatch, getState)

            // PubSub.publish('us-east-1:16e0e7e0-d751-4685-9ec5-b7139cc8cf18', { type: Actions.COMPANIES, payload: { companies: [company] } })
        }

        uploadImage(file)
            .then(saveCompany)
            .then(updateUI)
            .catch(error => {
                const { response: { data } } = error
                dispatch({ type: Actions.APP_ERROR, error })
            })

    }
}

import { API } from "aws-amplify";
import { Actions, ListActions } from 'Constants'

export const getSearch = state => state.paymentProcessors.list.search

export const toggleEditor = (paymentProcessor) => ({
    type: Actions.UI_PAYMENT_PROCESSORS_SHOW_EDITOR,
    paymentProcessor
})

export const search = (companyId, appId, search, LastEvaluatedKey) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_PAYMENT_PROCESSORS_LIST, listAction: ListActions.SEARCH, search })

        if (!appId) { return }

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    appId,
                    type: 'settings|paymentProcessor',
                    search,
                    ...(LastEvaluatedKey && {ExclusiveStartKey : LastEvaluatedKey})
                }
            }

            API.post("activations", "/application/find", req).then(response => {
                const { Count:count, ScannedCount, Items:items, LastEvaluatedKey } = response.data

                // Check search params haven't changed
                const currentSearch = getSearch(getState())
                // if (currentSearch !== search) return


                // array of paymentProcessors, merge meta into main object
                const paymentProcessors = items.reduce((result, item) => {
                    const { meta, ...rest } = item
                    return [ ...result, { ...rest, ...meta } ]
                }, [])

                dispatch({ type: Actions.UI_PAYMENT_PROCESSORS_LIST, listAction: ListActions.ITEMS, items: paymentProcessors, LastEvaluatedKey })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

export const loadMore = (companyId, appId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const search = state.paymentProcessors.list.search
        const LastEvaluatedKey = state.paymentProcessors.list.LastEvaluatedKey

        dispatch(search(companyId, appId, search, LastEvaluatedKey))
    }
}

export const select = (paymentProcessor) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_PAYMENT_PROCESSORS_SELECT, paymentProcessor })
    }
}


export const getFastspringLink = (companyId, appId, paymentProcessorId) => {
    return async (dispatch, getState) => {
        dispatch({ type: Actions.UI_PAYMENT_PROCESSORS_FS_LINK, loading: true, fsLinkToken:undefined })

        try {
            let req = { // OPTIONAL
                headers: {}, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                body: {
                    companyId,
                    appId,
                    paymentProcessorId
                }
            }

            API.post("activations", `/application/fastspring`, req).then(response => {
                const { success, fsLinkToken } = response.data
                dispatch({ type: Actions.UI_PAYMENT_PROCESSORS_FS_LINK, loading:false, fsLinkToken })

            }).catch(error => {
                console.log(error)
            })

        } catch (e) {
            // alert(e);
        }
    }
}

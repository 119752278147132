import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        "radio label"
        "empty info";
    align-items: center;
`
const Input = styled.input`
    margin: 1em;
    grid-area: radio;
`
const Label = styled.div`
    grid-area: label;
`
const Info = styled.div`
    grid-area: info;
    color: gray;
`
export const Radio = ({label, description, ...rest}) => (
    <Container>
        <Input type="radio" {...rest} />
        <Label>{label}</Label>
        <Info>
            This version will become live. Update will become available to all users and this version's binaries will be available by the default download links.
        </Info>
    </Container>
)

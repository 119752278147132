import { Actions } from 'Constants'

import { defaultListState, processList } from './InfiniteListReducer'

const defaultState = {
    list: defaultListState,
    selectedId: undefined,
    showEditor: false,
    loadingFSLink: false,
    fsLinkToken: undefined,

    isLoading: true,
    paymentProcessorsById: {}
}

const paymentProcessorId = (paymentProcessor) => { return paymentProcessor.paymentProcessorId }

const PaymentProcessors = (state = defaultState, action) => {
    switch (action.type) {

        case Actions.PAYMENT_PROCESSOR_DATA:
            const { isLoading = false, paymentProcessors = [] } = action.payload
            const paymentProcessorsById = isLoading ? {} : paymentProcessors.reduce((result, paymentProcessor) => {
                return { ...result, [paymentProcessor.paymentProcessorId]:paymentProcessor };
            }, state.paymentProcessorsById)

            return {
                ...state,
                isLoading,
                paymentProcessorsById
            }

        case Actions.UI_PAYMENT_PROCESSORS_LIST:
            return {
                ...state,
                list: processList(state.list, action, paymentProcessorId)
            }

        // case Actions.UI_CHANGE_COMPANY:
        //     return defaultState
        //
        // case Actions.UI_CHANGE_APP:
        //     return defaultState

        case Actions.UI_PAYMENT_PROCESSORS_SELECT:
            return {
                ...state,
                selectedId: action.paymentProcessor.paymentProcessorId
            }

        case Actions.UI_PAYMENT_PROCESSORS_TOGGLE_EDITOR: {
            return {
                ...state,
                showEditor: !state.showEditor
            }
        }

        case Actions.UI_PAYMENT_PROCESSORS_FS_LINK: {
            return {
                ...state,
                loadingFSLinkToken: action.loading,
                fsLinkToken: action.fsLinkToken
            }
        }

        default:
        return state
    }
}

export default PaymentProcessors

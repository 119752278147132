import React from 'react'
import { List, Item } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { performAppNav } from 'actions/nav'
import { getApps } from 'selectors'
import { totals } from 'selectors/appStats'

import styled from 'styled-components'

import AppInfoHeader from 'AppInfoHeader'

const makeComparator = (key, order='asc') => {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

        const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (aVal > bVal) comparison = 1;
        if (aVal < bVal) comparison = -1;

        return order === 'desc' ? (comparison * -1) : comparison
    }
}

const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: auto 10em 10em 10em;
    grid-template-rows: 2em auto;
`

const StyledList = styled(List)`
    height: 100%;
`

const StyledListHeader = styled.div`
    height: 2em;
    display: flex;
    align-items: center;
    font-size: .9em;
    color: #9a9a9a;
    border-bottom: 1px solid #ececec !important;
`

const StyledHeaderRow = styled.div`
    display: flex !important;
    align-items: center;
    padding: 0.5em 1em;
    border-bottom: 1px solid #ececec !important;
    position: sticky !important;
    top: 0  !important;
    background: white;
    z-index: 1;
`

const StyledRow = styled(List.Item)`
    display: flex !important;
    align-items: center;
    border: 0 !important;
    padding: .8em 1em !important;
    border-bottom: 1px solid #ececec !important;
`

const StyledListHeaderItem = styled.div`
    font-size: .9em;
    color: #9a9a9a;
    align-self: center;
    position: sticky;
    top: 0;
    background white;
`

const PrimaryColumn = styled.div`
    flex: 1;
`

const DetailColumn = styled.div`
    width: 10em;
    text-align: right;
`

const ColumnText = styled.div`
    flex: 1fr;
    font-weight: 300;
    font-size: 1.5em;
    color: #446f7b;
`

const statisticValue = (totals, appId, statistic) => {
    const stats = totals[appId] || {}
    return Math.round(stats[statistic]) || 0
}

class AppList extends React.Component {

    selectApp(app) {
        const { performAppNav, match: { params: { companyId } } } = this.props
        const { appId } = app
        performAppNav({companyId, appId})
    }

    appItems() {
        const { apps, totals } = this.props

        return apps.sort(makeComparator('name')).map(app =>
            <StyledRow key={app.appId} onClick={ () => this.selectApp(app) }>
                <PrimaryColumn>
                    <AppInfoHeader app={app} small />
                </PrimaryColumn>
                <DetailColumn><ColumnText>{statisticValue(totals, app.appId, 'downloads')}</ColumnText></DetailColumn>
                <DetailColumn><ColumnText>{statisticValue(totals, app.appId, 'installs')}</ColumnText></DetailColumn>
                <DetailColumn><ColumnText>${statisticValue(totals, app.appId, 'revenue')}</ColumnText></DetailColumn>
            </StyledRow>
        )
    }

    render() {

        return (
            <StyledList divided selection style={{margin:0, overflowY: 'auto'}}>
                <StyledHeaderRow>
                    <PrimaryColumn><StyledListHeaderItem>Name</StyledListHeaderItem></PrimaryColumn>
                    <DetailColumn><StyledListHeaderItem>Downloads</StyledListHeaderItem></DetailColumn>
                    <DetailColumn><StyledListHeaderItem>Installs</StyledListHeaderItem></DetailColumn>
                    <DetailColumn><StyledListHeaderItem>Revenue</StyledListHeaderItem></DetailColumn>
                </StyledHeaderRow>
                { this.appItems() }
            </StyledList>
        )
    }
}
// <List divided selection style={{margin:0, overflowY: 'auto'}}>
//     { this.appItems() }
// </List>

const mapStateToProps = (state, props) => ({
    apps: getApps(state, props),
    totals: totals(state, props)
})

const mapDispatchToProps = dispatch => ({
    performAppNav: (...params) => dispatch(performAppNav(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppList)
